import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Tooltip } from '@material-ui/core';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { CheckboxWithLabel } from 'components/createNewV3/CreateNew';
import Dialog from 'components/dialogs/DialogBuilder';
import { Alternative, FieldTypeEnum, Mdf, MdfField } from 'types/graphqlTypes';

const getSubtypeFields = (mdfs: Mdf[]): Set<string> => {
  const types = new Set<string>();
  for (const mdf of mdfs) {
    for (const subTypeField of mdf.fields.filter((md) => md.type === FieldTypeEnum.subtype)) {
      (subTypeField.alternatives ?? []).forEach((alt) => types.add(alt.label));
    }
  }
  return types;
};

// EG given subtype A, B, C, D
// if C is configured in subtype B, it should not be available in A and D
// if any "main" mdf configures a subtype, it should not be available in any subtype
// a subtype cannot configure itself in a subtype
const getAvailableSubtypes = (
  mdfs: {
    defaults: Mdf[];
    custom: Mdf[];
    subTypes: Mdf[];
  },
  selectedMdf: Mdf,
): Record<string, string | Alternative> => {
  const availableSubTypes: Mdf[] = [...mdfs.subTypes.filter((m) => selectedMdf.label !== m.label)];
  const configuredSubtypeFieldsInOtherSubtypes: Set<string> = getSubtypeFields(availableSubTypes);

  const resultMap: Record<string, string | Alternative> = {};
  for (const mdf of mdfs.subTypes) {
    if (selectedMdf.label === mdf.label) {
      resultMap[mdf.label] = 'Cannot configure itself as a field group';
    } else if (selectedMdf.isSubtype) {
      if (configuredSubtypeFieldsInOtherSubtypes.has(mdf.label)) {
        resultMap[mdf.label] =
          'This field group is already configured in a different field group schema';
      } else {
        resultMap[mdf.label] = { id: mdf.label, value: mdf.label, label: mdf.label };
      }
    } else {
      resultMap[mdf.label] = { id: mdf.label, value: mdf.label, label: mdf.label };
    }
  }

  return resultMap;
};

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  field: MdfField;
  updateAlternatives: (alts: Alternative[]) => void;
  selectedMdf: Mdf;
}

export function ConfigSubtypeDialog({
  open,
  setOpen,
  updateAlternatives,
  field,
  selectedMdf,
}: Readonly<Props>) {
  const { mdfsSeparated } = useGetMdfs(); // may need to grab changedMdfs
  const [fieldToConfigure, setFieldToConfigure] = useState({ ...field });

  useEffect(() => {
    setFieldToConfigure({ ...field });
  }, [field]);

  const toggleSubtype = useCallback(
    (label: string) => {
      const copy = [...(fieldToConfigure.alternatives ?? [])];
      if (copy.find((alt) => alt.label === label) !== undefined) {
        setFieldToConfigure((prevValue) => {
          return {
            ...prevValue,
            alternatives: copy.filter((s) => s.label !== label),
          };
        });
      } else {
        setFieldToConfigure((prevValue) => {
          return {
            ...prevValue,
            alternatives: [...copy, { id: label, value: label, label }],
          };
        });
      }
    },
    [mdfsSeparated, fieldToConfigure, setFieldToConfigure],
  );

  const selectedSubtypes = useMemo(() => {
    return (fieldToConfigure.alternatives ?? []).map((alt) => alt.label);
  }, [fieldToConfigure.alternatives]);

  const availableSubtypes = useMemo(() => {
    return getAvailableSubtypes(mdfsSeparated, selectedMdf);
  }, [mdfsSeparated, selectedMdf]);

  const confirmChanges = useCallback(() => {
    updateAlternatives(fieldToConfigure.alternatives ?? []);
    setOpen(false);
  }, [updateAlternatives, fieldToConfigure]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '300px', maxWidth: '300px' }}
    >
      <Dialog.Header>Set available sub type choices</Dialog.Header>
      <Dialog.Body>
        <Box>
          {Object.entries(availableSubtypes).map(([key, value]) => (
            <Box flexDirection="row" key={key}>
              <Tooltip title={typeof value === 'string' ? value : ''} placement="left">
                <span>
                  <CheckboxWithLabel
                    selected={selectedSubtypes.includes(key)}
                    onClick={() => {
                      if (typeof value !== 'string') toggleSubtype(key);
                    }}
                    label={key}
                    disabled={typeof value === 'string'}
                  />
                </span>
              </Tooltip>
            </Box>
          ))}
        </Box>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton label="Confirm" onConfirm={confirmChanges} />
      </Dialog.Footer>
    </Dialog>
  );
}
