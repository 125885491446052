import { useCallback } from 'react';

import { SearchDateRange } from 'api/search';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { BUCKET_DATE_FORMAT } from 'screens/storyHub/store/storyHub';
import { BucketResponse, MemberType } from 'types/graphqlTypes';

export const DATE_FORMAT = 'yyyy-MM-dd';

export type GroupedByDateData = {
  [x: string]: MemberType[];
};

const useGroupDate = () => {
  const { eachDayOfInterval, format, startOfDay, isValid, isWithinInterval } = useDateTimeUtils();

  const getBucketKey = useCallback((mPublishingAt?: string) => {
    if (!mPublishingAt) return;
    if (!isValid(new Date(mPublishingAt))) return;
    return format(startOfDay(mPublishingAt), BUCKET_DATE_FORMAT);
  }, []);

  const getEachDaysInDateRange = useCallback(
    (dateRange: SearchDateRange) => eachDayOfInterval({ start: dateRange.from, end: dateRange.to }),
    [],
  );

  const getEachDaysInDateRangeFormatted = useCallback(
    (dateRange: SearchDateRange, dateFormat: string) =>
      eachDayOfInterval({ start: dateRange.from, end: dateRange.to }).map((date) =>
        format(date, dateFormat),
      ),
    [],
  );

  const getGroupedByDateData = useCallback(
    (items: MemberType[], dateRange: SearchDateRange) => {
      const groups = {} as GroupedByDateData;
      getEachDaysInDateRange(dateRange).forEach((date) => {
        groups[format(date, DATE_FORMAT)] = [];
      });
      items.forEach((item) => {
        const groupKey = format(item.mPublishingAt as string, DATE_FORMAT);
        if (groups[groupKey]) groups[groupKey].push(item);
      });
      return groups;
    },
    [getEachDaysInDateRange],
  );

  const getGroupedByDateBuckets = useCallback(
    (buckets: BucketResponse[], dateRange: SearchDateRange) => {
      const responses = {} as Record<string, BucketResponse>;

      buckets.forEach((bucket) => {
        if (bucket.stringKey) {
          const stringKey = getBucketKey(bucket.stringKey);
          if (
            stringKey &&
            isWithinInterval(stringKey, { start: dateRange.from, end: dateRange.to })
          )
            responses[stringKey] = bucket;
        }
      });
      return responses;
    },
    [getEachDaysInDateRange],
  );

  return {
    getGroupedByDateBuckets,
    getGroupedByDateData,
    getBucketKey,
    getEachDaysInDateRangeFormatted,
  };
};

export default useGroupDate;
