import { useRef } from 'react';

import { deepEquals } from 'utils/objectUtils';

/**
 * Memoes a map from string keys to object values. If the incoming map is deeply equal to the
 * previous incoming map, the previous incoming map will be returned. If any value (for a given key)
 * is deeply equal to the previous value for that key, the previous value for that key will be used.
 * @param source        The map to be memoed
 * @returns             The memoed map
 */
export default function useMemoMap<T extends Record<string, unknown>>(
  source: Readonly<Record<string, T>>,
): Readonly<Record<string, T>> {
  const prev = useRef(source);
  if (prev.current === source) {
    return source;
  }
  const newMap: Record<string, T> = {};
  const prevCount = Object.keys(prev.current).length;
  const sourceEntries = Object.entries(source);
  let changed = prevCount !== sourceEntries.length;
  sourceEntries.forEach(([key, value]) => {
    const prevValue = prev.current[key];
    if (deepEquals(prevValue, value)) {
      newMap[key] = prevValue;
    }
    changed = true;
    newMap[key] = value;
  });
  if (!changed) return prev.current;
  prev.current = Object.freeze(newMap);
  return newMap;
}
