import { memo, Suspense, useCallback, useContext, useEffect, useMemo } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { ScopeProvider } from 'jotai-molecules';

import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import variants from 'components/editor/constants/types/editorVariants';
import Editor from 'components/editor/Editor';
import { Update } from 'components/editor/types';
import EmptyState from 'components/emptyState/EmptyState';
import LockedIndicator from 'components/lockedIndicator';
import UserContext from 'contexts/UserContext';
import { useNotesMolecule } from 'features/notes/store';
import useGetMembersInfo from 'hooks/useGetMembersInfo';
import { ResourceDetails } from 'hooks/useResourceDetails';
import { Box } from 'layouts/box/Box';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import { useStoryToolbar } from 'screens/storyV2/store/toolbar';
import { useDoubleClickToLockEditor, useUsers } from 'store';
import { Note } from 'types';
import { getScopeFromLockedId, getUserIdFromLockedId } from 'utils/lock/lockTokenV2';

import useNoteBeforeUnload from './hooks/useNoteBeforeUnload';
import useNoteEditor from './hooks/useNoteEditor';
import { NoteScope, useNoteMolecule } from './store';

import { EditorWrapper } from './styled';

interface NotesViewProps {
  canUpdate: boolean;
  hostReadSpeed?: number;
  note?: Note;
  resourceDetails?: ResourceDetails;
}

const NoteView: React.FC<NotesViewProps> = ({
  note,
  canUpdate,
  hostReadSpeed,
  resourceDetails,
}) => {
  const { currentEditingScope } = useNotesMolecule();
  const { baseAtom, setNoteAtom, updateEditPermissionAtom } = useNoteMolecule();

  const updateNote = useSetAtom(setNoteAtom);
  const updateEditPermission = useSetAtom(updateEditPermissionAtom);
  const { getMemberTitle } = useGetMembersInfo();

  const [{ editorFontSize }] = useStoryToolbar();
  const [doubleClickToLockEditor] = useDoubleClickToLockEditor();
  const { content, isLocked, lockedBy, isSaving, isLoading } = useAtomValue(baseAtom);
  const { mId: currentUserId } = useContext(UserContext);
  const [users] = useUsers();

  const memoizedResourceDetails = useMemo(() => resourceDetails, [resourceDetails]);
  const memoizedNote = useMemo(() => note, [note]);

  /** custom hook to handle before unload functionality */
  useNoteBeforeUnload();

  useEffect(() => {
    /** saves updated note to state */
    updateNote(memoizedNote ?? null);
  }, [memoizedNote, updateNote]);

  useEffect(() => {
    updateEditPermission(canUpdate);
  }, [canUpdate, updateEditPermission]);

  const {
    onFocusEditor,
    onForceUnlock,
    onEditorUpdate,
    onCancel,
    onSave,
    shouldResetSelection,
    contentLoading,
  } = useNoteEditor();

  const { isSameScope, lockedUserId } = useMemo(
    () => ({
      isSameScope: currentEditingScope === getScopeFromLockedId(lockedBy),
      lockedUserId: getUserIdFromLockedId(lockedBy),
    }),
    [lockedBy, currentEditingScope],
  );

  const getPlaceholderConfigs = useCallback(
    () => ({
      template: {},
      s3Content: null,
      variables: {},
    }),
    [],
  );

  const onClick = useCallback(() => {
    if (doubleClickToLockEditor) return;
    onFocusEditor().catch(() => {});
  }, [doubleClickToLockEditor, onFocusEditor]);

  const onDoubleClick = useCallback(() => {
    if (!doubleClickToLockEditor) return;
    onFocusEditor().catch(() => {});
  }, [doubleClickToLockEditor, onFocusEditor]);

  if (!note) {
    return <EmptyState message="Please select a note" />;
  }

  const readLock =
    (isLocked && lockedUserId !== currentUserId) ||
    (isLocked && lockedUserId === currentUserId && !isSameScope);

  const writeLock = isSameScope && isLocked && lockedUserId === currentUserId;

  const readOnly = !isSameScope || !canUpdate || readLock || !writeLock;

  return (
    <Box container flex="1" width="100%" height="100%">
      <Suspense fallback={<DebouncedLoadingIndicator />}>
        <DebouncedLoadingIndicator isLoading={isLoading || contentLoading} />
        <EditorWrapper
          $readLock={readLock}
          $writeLock={writeLock}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          width="100%"
          height="100%"
          overflow="hidden"
          flexDirection="column"
        >
          <Editor
            variant={variants.NOTES}
            value={content}
            update={onEditorUpdate as Update}
            onSave={onSave}
            users={users}
            renderToolbar={writeLock ? undefined : () => null}
            readOnly={readOnly}
            toolbarPosition="top"
            placeholder="Type Something..."
            fallbackText="Note content can not be loaded"
            height={readLock || writeLock ? 'calc(100% - 40px)' : '100%'}
            shouldResetSelection={shouldResetSelection}
            hostReadSpeed={hostReadSpeed}
            resourceDetails={memoizedResourceDetails}
            getPlaceholderConfigs={getPlaceholderConfigs}
            editorFontSize={editorFontSize}
            enableEditorCommand
            showSidepanelButton
            showHoveringTooltip
          />
          <LockedIndicator
            canUnlock={canUpdate}
            readLock={readLock}
            writeLock={writeLock}
            lockedBy={lockedUserId ? getMemberTitle(lockedUserId) ?? 'Someone' : 'Someone'}
            isSaving={isSaving}
            onDone={onSave}
            onCancel={onCancel}
            lockedId={lockedBy ?? undefined}
            onForceUnlock={onForceUnlock}
            isCurrentUser={lockedUserId === currentUserId}
            disableSave={readOnly || !canUpdate || contentLoading || isSaving || isLoading}
          />
        </EditorWrapper>
      </Suspense>
    </Box>
  );
};

const ScopedNote = (props: NotesViewProps) => {
  const { usePaneIndexValue } = useStoryPaneMolecule();
  const paneIndex = usePaneIndexValue();

  return (
    <ScopeProvider
      scope={NoteScope}
      value={`${props.note?.mRefId}${paneIndex ? `:${paneIndex}` : ''}`}
    >
      <NoteView {...props} />
    </ScopeProvider>
  );
};

export default memo(ScopedNote);
