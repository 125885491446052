import React, { memo, useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { SearchParameters } from 'api/search';
import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as Refresh } from 'assets/icons/systemicons/refresh_small.svg';
import useOpenMember from 'components/contextMenu/useOpenMember';
import { useCreateMemberDialog } from 'components/createNewV3/CreateNewDialog';
import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import Tooltip from 'components/tooltip/Tooltip';
import { canOpenPreview } from 'features/preview/utils';
import SearchDeck from 'features/searchDeck';
import FilterComponent from 'features/searchDeck/components/filters';
import WidgetWrapper, { FilterComponentType } from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';
import { useSetPreview } from 'store/preview';
import { Metadata } from 'types/forms/forms';
import { MdfField, MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import { FilterValueType } from 'types/widget';

import { StoryWidgetProps } from './types';
import { getPropFilters } from './utils';

const StyledAdd = styled(Add)`
  cursor: pointer;
  margin: 0 6px;
  &:hover * {
    fill-opacity: 1;
  }
`;
const StyledRefresh = styled(Refresh)`
  cursor: pointer;
  margin: 0 6px;
  &:hover * {
    fill-opacity: 1;
  }
`;

const getScheduledDateFromState = (
  federatedDate: DateRange | undefined,
  configuredFilters: SearchParameters,
): string | undefined => {
  if (federatedDate) return federatedDate.startDate;
  return configuredFilters.toolbarState.rangeBy?.scheduledAt?.from;
};

export const isStoryFilter = (
  obj: FilterValueType | undefined,
): obj is { searchParams: SearchParameters } => {
  if (!obj) return false;
  const maybe = obj as Partial<{ searchParams: SearchParameters }>;
  return maybe.searchParams !== undefined;
};

export const getAssigneeIds = (obj: SearchParameters) => {
  return obj.toolbarState.assignedIds ?? [];
};

export const getRelevantStoryMetadata = (
  obj: SearchParameters,
  storyMdfFields: Record<string, MdfField>,
) => {
  const relevantMetadata: Metadata = {};
  for (const [key, value] of Object.entries(obj.metadataFilter ?? {})) {
    if (storyMdfFields[key]) {
      relevantMetadata[key] = value;
    }
  }
  return {
    mdfId: 'story-mdf',
    metadata: relevantMetadata,
  };
};

function SearchWidget({
  layout,
  filters,
  mId,
  mRefId,
  selectedDate,
  federatedSearchString,
  title,
  writeAccess,
}: Readonly<StoryWidgetProps>) {
  const { mdfsByMType } = useGetMdfs();
  const { openItem } = useOpenMember();
  const [counter, setCounter] = useState(0);
  const [, showCreateMemberDialog] = useCreateMemberDialog();
  const setItemPreview = useSetPreview();

  const storyMdfFields = useMemo(() => {
    return keyBy(mdfsByMType.story?.fields ?? [], (f) => f.fieldId);
  }, [mdfsByMType]);

  const memoizedFilters = useMemo(() => {
    const propFilters = getPropFilters({ filters });
    return propFilters;
  }, [filters]);

  const handlePreviewItem = useCallback(
    (member: MemberType) => {
      if (
        member.mType === MemberTypeEnum.Rundown ||
        member.mType === MemberTypeEnum.Asset ||
        member.mType === MemberTypeEnum.Space ||
        member.mType === MemberTypeEnum.Note
      ) {
        openItem(member);
      } else if (canOpenPreview(member.mType)) {
        setItemPreview(member);
      }
    },
    [openItem],
  );

  const doCreateStory = useCallback(
    (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      if (isStoryFilter(memoizedFilters)) {
        showCreateMemberDialog({
          mTitle: '',
          forceShow: true,
          anchorEl: ev.currentTarget,
          preselectedMetadata: getRelevantStoryMetadata(
            memoizedFilters.searchParams,
            storyMdfFields,
          ),
          scheduleDate: getScheduledDateFromState(selectedDate, memoizedFilters.searchParams),
          onComplete: () => setCounter(counter + 1),
        });
      }
    },
    [showCreateMemberDialog, memoizedFilters, selectedDate, setCounter, counter],
  );

  return (
    <WidgetWrapper
      useOverflow={false}
      layout={layout}
      mId={mId}
      mRefId={mRefId}
      title={title}
      writeAccess={writeAccess}
      filterComponent={FilterComponent as FilterComponentType}
      filters={memoizedFilters}
      activeFilters={false}
      type={WIDGETS.SEARCH}
      headerProps={
        <>
          <Tooltip title="Refresh">
            <StyledRefresh onClick={() => setCounter(counter + 1)} />
          </Tooltip>
          <Tooltip title="Create story">
            <StyledAdd onClick={doCreateStory} />
          </Tooltip>
        </>
      }
    >
      <SearchDeck
        filters={memoizedFilters}
        selectedDate={selectedDate}
        previewItem={handlePreviewItem}
        refreshCounter={counter}
        federatedSearchString={federatedSearchString}
      />
    </WidgetWrapper>
  );
}

export default memo(SearchWidget);
