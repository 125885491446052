/* eslint-disable no-console */
import { Auth } from '@aws-amplify/auth';

import { AuthContextProps } from 'contexts/AuthContext';

const AuthStates = {
  VERIFIED: 'verified',
  NOT_VERIFIED: 'not_verified',
  VERIFYING: 'verifying',
};
const AuthStateId = 'state';

const getAuthState = () => {
  const { localStorage } = window;
  return localStorage.getItem(AuthStateId);
};

const setAuthState = (state: string) => {
  const { localStorage } = window;
  const currentState = getAuthState();
  localStorage.setItem(AuthStateId, state);
  return currentState;
};

const openFederatedSignIn = async () => {
  try {
    await Auth.federatedSignIn();
  } catch (error) {
    console.error(`Auth.federateSignIn failed: ${String(error)}`);
  }
};

const getSignInUser = (context: AuthContextProps | null) => {
  const user = { mId: '-', groups: [] as string[] };
  if (!context?.user) {
    return user;
  }

  const idToken = context.user.getSignInUserSession()?.getIdToken();
  if (idToken?.payload && 'userId' in idToken.payload) {
    user.mId = idToken.payload.userId as string;
  }
  user.groups = context.groups;
  return user;
};

const toBoolean = (strBool: string | undefined) => strBool?.toUpperCase() === 'TRUE';

const UseHostedUI = toBoolean(import.meta.env.REACT_APP_USE_HOSTED_UI as string | undefined);
const UseAuthAudit = toBoolean(import.meta.env.REACT_APP_AUTH_AUDIT as string | undefined);

/** Cognito authorization */
const CognitoAuth = {
  Auth: {
    region: import.meta.env.REACT_APP_AWS_AUTH_REGION as string,
    userPoolId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_ID as string,
    userPoolWebClientId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID as string,
    identityPoolId: import.meta.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID as string,
  },
};

/** Hosted UI authorization */
const HostedUIAuth = {
  Auth: {
    region: import.meta.env.REACT_APP_AWS_AUTH_REGION as string,
    userPoolId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_ID as string,
    userPoolWebClientId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID as string,
    identityPoolId: import.meta.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID as string,
    oauth: {
      domain: import.meta.env.REACT_APP_AWS_COGNITO_DOMAIN as string,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${window.origin}/`,
      redirectSignOut: `${window.origin}/`,
      responseType: 'code', // or 'token', REFRESH token will only be created for responseType=code
    },
  },
};

if (import.meta.env.REACT_APP_AUTHENICATION_FLOW) {
  (HostedUIAuth.Auth as Record<string, unknown>).authenticationFlowType = import.meta.env
    .REACT_APP_AUTHENICATION_FLOW as string;
}

const getAuthConfiguration = () => (UseHostedUI ? HostedUIAuth : CognitoAuth);

const isGroupMember = (userGroups: readonly string[], groups: readonly string[]) =>
  groups.some((group) => userGroups.includes(group));

export {
  openFederatedSignIn,
  getSignInUser,
  getAuthConfiguration,
  UseHostedUI,
  UseAuthAudit,
  isGroupMember as default,
  getAuthState,
  setAuthState,
  AuthStates,
};
