import { useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import EditTitleCell from 'features/grids/common/components/cells/EditTitleCell';
import { PlanningTypeCell } from 'features/grids/common/components/cells/PlanningTypeCell';
import { useGetMdfColumns } from 'features/mdf/useGetMdfColumns';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import { ParsedMemberType } from 'types';
import type { Mdf } from 'types/graphqlTypes';

import { actionColumn } from './actionColumn';

export function useGetPlanningColumns(blocks: ParsedMemberType[], mdfs: Mdf[]) {
  const { isoToLocaleShort } = useCustomDateTimeUtils();
  const [blockIds, setBlockIds] = useState<string[]>([]);
  if (blocks.length !== blockIds.length) {
    setBlockIds(blocks.map((block) => block.mRefId as string));
  }
  const mdfDefinitions = useGetMdfColumns({ items: blocks, mdfs });

  const defaultPlanningColumns: ColumnDef<ParsedMemberType>[] = useMemo(
    () => [
      {
        accessorKey: 'commandData.mTitle',
        header: 'Type',
        size: 180,
        cell: ({ row }) => <PlanningTypeCell row={row} />,
        filterFn: 'arrIncludesSome',
      },
      {
        accessorKey: 'mTitle',
        header: 'Title',
        filterFn: (row, _, filterValue: string = '') => {
          const mTitle = row.original.mTitle ?? '';
          return mTitle?.toLowerCase().includes(filterValue.toLowerCase());
        },
        cell: ({ row, getValue, table }) => (
          <EditTitleCell
            row={row}
            getValue={getValue}
            updateTitle={table.options.meta?.updateTitle}
          />
        ),
      },
      {
        accessorKey: 'mCreatedAt',
        cell: ({ getValue }) => isoToLocaleShort(getValue() as string),
        header: 'Created',
        size: 140,
      },
      {
        accessorKey: 'mUpdatedAt',
        cell: ({ getValue }) => isoToLocaleShort(getValue() as string),
        header: 'Updated',
        size: 140,
      },
    ],
    [],
  );

  const columns = useMemo(() => {
    return [...defaultPlanningColumns, ...mdfDefinitions, actionColumn];
  }, [blockIds]);

  return columns;
}
