import { memo, useCallback, useEffect, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import PropTypes from 'prop-types';

import AutoCompleteBase from 'components/autoCompleteBase';
import ChipGroup from 'components/editor/components/tags/components/chipGroup';
import { useEditorContext } from 'components/editor/EditorContext';
import InputFieldComponent from 'components/inputField';
import TextAreaComponent from 'components/textArea';
import useInputEvents from 'hooks/useInputEvents';
import { CheckboxWithLabel } from 'lib/checkbox';

import { InputWrapper, TextAreaWrapper } from './styled';

const InputField = memo(InputFieldComponent);
const TextArea = memo(TextAreaComponent);

const Checkbox = memo(CheckboxWithLabel);

const Metadata = ({
  textareaProps,
  sourceProps,
  titleProps,
  inputProps,
  isCmsVariant,
  updateMetadata,
  isCoverphoto,
  coverOnly,
  direction,
}) => {
  const { isCmsBlock, onHotKeys } = useEditorContext();

  /** combobox value setters */
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const [caption, setCaption] = useState(textareaProps.value);
  const [title, setTitle] = useState(titleProps.value);
  const [artist, setArtist] = useState(inputProps.value);

  const onTitleUpdate = useCallback(
    (newValue) => {
      if (newValue === titleProps.value) setTitle(titleProps.value);
      else updateMetadata(newValue, titleProps.propName);
    },
    [titleProps, updateMetadata],
  );

  const onInputUpdate = useCallback(
    (newValue) => {
      if (newValue === inputProps.value) setArtist(inputProps.value);
      else updateMetadata(newValue, inputProps.propName);
    },
    [inputProps, updateMetadata],
  );

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(
    onInputUpdate,
    artist,
    inputProps.value,
    false,
    onHotKeys,
  );

  const [titleRef, handleTitleKeydown, handleTitleBlur] = useInputEvents(
    onTitleUpdate,
    title,
    titleProps.value,
    false,
    onHotKeys,
  );

  const onTextareaKeyDown = useCallback(
    (event) => {
      const { key } = event;
      const { propName } = textareaProps;

      if (key === 'Enter') {
        event.preventDefault();
        updateMetadata(caption, propName);
      }
      if (key === 'Tab') {
        updateMetadata(caption, propName);
      }
      if (key === 'Escape') setCaption(textareaProps.value);

      onHotKeys(event, () => updateMetadata(caption, propName));
    },
    [caption, onHotKeys, textareaProps, updateMetadata],
  );

  const onTextareaBlur = useCallback(
    (event) => {
      event.preventDefault();
      if (event.relatedTarget) updateMetadata(caption, textareaProps.propName);
    },
    [caption, textareaProps, updateMetadata],
  );

  const handleCheckboxClick = useCallback(
    (event) => {
      updateMetadata(!isCoverphoto, 'isCoverphoto');
    },
    [isCoverphoto, updateMetadata],
  );

  const handleCoverOnlyCheckboxClick = useCallback(() => {
    /** We will update this field only when the isCoverPhoto meta data is true */
    if (isCoverphoto) updateMetadata(!coverOnly, 'coverOnly');
  }, [coverOnly, updateMetadata, isCoverphoto]);

  const handleChange = (event, newObj) => {
    event.preventDefault();
    if (newObj) {
      const isIncluded = sourceProps.value.find((opt) => opt.value === newObj.value);

      if (!isIncluded) {
        const newData = [...sourceProps.value, { id: newObj.id, value: newObj.value }];
        updateMetadata(newData, sourceProps.propName);
      }
      setInputValue('');
      setValue(null);
    }
  };

  const removeChip = (event, index) => {
    event.preventDefault();
    const newData = sourceProps.value.filter((_, pos) => pos !== index);
    updateMetadata(newData, sourceProps.propName);
  };

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
  } = useAutocomplete({
    id: 'source-box',
    options: sourceProps.options,
    autoHighlight: true,
    getOptionLabel: (option) => option.value,
    value,
    onChange: handleChange,
    inputValue,
    onInputChange: (_, newValue) => setInputValue(newValue),
  });

  useEffect(() => {
    setCaption(textareaProps?.value);
  }, [textareaProps?.value]);

  useEffect(() => {
    setTitle(titleProps.value);
  }, [titleProps.value]);

  useEffect(() => {
    setArtist(inputProps.value);
  }, [inputProps.value]);

  return (
    <div>
      {!isCmsVariant && (
        <InputField
          disableLabel
          usage="editor"
          inputRef={titleRef}
          onBlur={handleTitleBlur}
          value={title}
          onChange={setTitle}
          onKeyDown={handleTitleKeydown}
          placeholder={titleProps.placeholder}
          description={titleProps.description}
          direction={direction}
        />
      )}
      <TextAreaWrapper>
        <TextArea
          rows={3}
          disableLabel
          severity="regular"
          type="SoMe"
          value={caption}
          onChange={setCaption}
          placeholder={textareaProps.placeholder}
          description={textareaProps.description}
          onKeyDown={onTextareaKeyDown}
          onBlur={onTextareaBlur}
          direction={direction}
        />
      </TextAreaWrapper>
      {isCmsVariant && (
        <InputWrapper>
          {isCmsBlock ? (
            <>
              <AutoCompleteBase
                content={sourceProps.value}
                value={inputValue}
                inputUsage="metadata"
                placeholder={sourceProps.placeholder}
                direction={direction}
                {...{
                  getRootProps,
                  getInputProps,
                  getListboxProps,
                  getOptionProps,
                  getClearProps,
                  groupedOptions,
                  getPopupIndicatorProps,
                  popupOpen,
                }}
              />
              <ChipGroup list={sourceProps.value} removeChip={removeChip} />
            </>
          ) : (
            <InputField
              disableLabel
              usage="editor"
              inputRef={inputRef}
              onBlur={handleBlur}
              value={artist}
              onChange={setArtist}
              onKeyDown={handleKeydown}
              placeholder={inputProps.placeholder}
              description={inputProps.description}
              direction={direction}
            />
          )}
        </InputWrapper>
      )}
      {isCmsBlock && (
        <InputWrapper>
          <Checkbox
            label="Lead Image"
            onCheckedChange={handleCheckboxClick}
            checked={isCoverphoto}
          />
          <Checkbox
            disabled={!isCoverphoto}
            label="Home page only"
            onCheckedChange={handleCoverOnlyCheckboxClick}
            checked={coverOnly}
          />
        </InputWrapper>
      )}
    </div>
  );
};

Metadata.propTypes = {
  /** sourceProps for input field */
  sourceProps: PropTypes.shape({}),
  /** textarea props for description or caption field */
  textareaProps: PropTypes.shape({}),
  /** title props for title input field */
  titleProps: PropTypes.shape({}),
  /** inputProps for input field */
  inputProps: PropTypes.shape({}),
  /** If the editor defined for cms variant */
  isCmsVariant: PropTypes.bool,
  /** If the block is image, whether it should be coverphoto or not  */
  isCoverphoto: PropTypes.bool,
  /** updatemetadata callback function */
  updateMetadata: PropTypes.func,
  /** If the block is image and if the image should be set as homepage only or not */
  coverOnly: PropTypes.bool,
};

Metadata.defaultProps = {
  sourceProps: {},
  textareaProps: {},
  titleProps: {},
  inputProps: {},
  isCmsVariant: false,
  isCoverphoto: false,
  updateMetadata: () => {},
  coverOnly: false,
};

export default memo(Metadata);
