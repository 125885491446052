import { memo, useCallback, useMemo, useState } from 'react';

import { useGetTypedOptionList } from 'api/optionLists/useGetOptionList';
import Text from 'components/text/Text';
import { Color } from 'features/reusableStyled';
import { Box } from 'layouts/box/Box';
import { FieldValue } from 'types/forms/forms';

import { CellProps } from '../fields';

import ChoiceField from './ChoiceField';

function ChoiceCell({
  fieldModel,
  fieldSettings,
  setValue,
  value,
  disableEdit,
}: Readonly<CellProps>) {
  const [isEditing, setIsEditing] = useState(false);
  const { colors = {} } = fieldSettings ?? {};
  const { optionListId } = fieldModel;
  const { optionList } = useGetTypedOptionList(optionListId, 'choice');
  const alternatives = useMemo(
    () => optionList?.alternatives ?? fieldModel.alternatives ?? [],
    [optionList, fieldModel],
  );

  const doEdit = useCallback(() => {
    if (!disableEdit) {
      setIsEditing(true);
    }
  }, [setIsEditing, disableEdit]);

  const doSetValue = useCallback(
    (val: FieldValue) => {
      setValue(val);
      setIsEditing(false);
    },
    [setValue, setIsEditing],
  );

  const userVisibleValue = useMemo(() => {
    return alternatives.find((a) => a.value === value);
  }, [alternatives, value]);

  if (!alternatives?.length) {
    return (
      <Text variant="captionItalic" color="mediumEmphasis">
        No options
      </Text>
    );
  }

  const color = colors[userVisibleValue?.label ?? (value as string)];

  if (!isEditing) {
    const label = value ? userVisibleValue?.label ?? (value as string) : 'Select';

    return (
      <Box
        container
        flexDirection="row"
        justifyContent="start"
        onClick={doEdit}
        gap="4px"
        cursor={disableEdit ? 'default' : 'pointer'}
        width="100%"
      >
        {color && <Color $color={color} $size={12} />}
        {label}
      </Box>
    );
  }

  return (
    <ChoiceField
      autoFocus
      hideLabel
      fieldModel={fieldModel}
      defaultFieldSettings={fieldSettings!}
      fieldSettings={fieldSettings}
      value={value}
      setValue={doSetValue}
      view="default"
      onBlur={() => setIsEditing(false)}
    />
  );
}

export default memo(ChoiceCell);
