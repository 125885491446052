/* eslint-disable max-len */
import React, { memo, useState, useCallback, useEffect } from 'react';
import { Transforms } from 'slate';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor } from 'slate-react';
import InputField from 'components/inputField';
import TextAreaComponent from 'components/textArea';
import { useEditorContext } from 'components/editor/EditorContext';
import { actionTypes } from 'components/editor/constants/types';
import LowerthirdDropdown from 'components/editor/components/titleBox/components/lowerthirdDropdown';
import processInput from './utils/processInput';
import processOutput from './utils/processOutput';
import { RootWrapper, InputWrapper, DropdownWrapper } from './styled';

const { setNodes } = Transforms;
const Input = memo(InputField);
const TextArea = memo(TextAreaComponent);

const CharacterGraphics = ({ data, resources, isGraphic, initialData }) => {
  const editor = useSlate();
  const { update, onHotKeys } = useEditorContext();
  const { starcg } = data;
  const { graphicsTemplateType, fields } = starcg;
  const [typeInputValue, setTypeInputValue] = useState(graphicsTemplateType);
  const [textInputValue, setTextInputValue] = useState(processOutput(fields));
  const { graphicsDestination } = initialData;

  const [isLowerthirdSelectOpen, setIsLowerthirdSelectOpen] = useState(false);

  const openLowerthirdSelectMenu = useCallback(() => setIsLowerthirdSelectOpen(true), []);
  const closeLowerthirdSelectMenu = useCallback(() => setIsLowerthirdSelectOpen(false), []);

  const onTextChange = (e) => {
    setTextInputValue(e);
  };

  const onTypeChange = (e) => {
    setTypeInputValue(e);
  };

  useEffect(() => {
    setTypeInputValue(graphicsTemplateType);
  }, [graphicsTemplateType]);

  useEffect(() => {
    setTextInputValue(processOutput(fields));
  }, [fields]);

  const onBlur = useCallback(() => {
    const textOutput = processInput(textInputValue);
    setTextInputValue(processOutput(textOutput));
    const typeOutput = typeInputValue;
    const newStarCg = { graphicsTemplateType: typeOutput, fields: textOutput };
    const updatedData = { ...data, starcg: newStarCg };

    setNodes(editor, { data: updatedData });
    update({
      type: actionTypes.AUTOMATION_UPDATE,
      payload: { document: editor.children, updatedData },
    });
  }, [data, editor, textInputValue, typeInputValue, update]);

  const updateGraphicsDestination = useCallback(
    (selectedValue) => {
      const updatedData = {
        ...initialData,
        graphicsDestination: selectedValue,
      };

      setNodes(editor, { data: updatedData });
      ReactEditor.focus(editor);
      update({
        type: actionTypes.AUTOMATION_UPDATE,
        payload: { document: editor.children, updatedData },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialData],
  );

  const handleLowerthirdSelection = useCallback(
    (selectedValue) => {
      const lowerthird = resources.graphicsDestinations.find((d) => d.value === selectedValue);
      if (lowerthird) {
        const { value } = lowerthird;
        updateGraphicsDestination(value);
      }
    },
    [resources, updateGraphicsDestination],
  );

  const onKeydown = useCallback((event) => onHotKeys(event, () => onBlur()), [onHotKeys, onBlur]);

  return (
    <RootWrapper>
      <InputWrapper>
        <Input
          usage="editor"
          label="Graphics Template Type"
          value={typeInputValue}
          onChange={onTypeChange}
          onBlur={onBlur}
          onKeyDown={onKeydown}
        />
        <TextArea
          type="editor"
          label="Text"
          optionalLabel="Text"
          value={textInputValue}
          onChange={onTextChange}
          onBlur={onBlur}
          onKeyDown={onKeydown}
        />
      </InputWrapper>
      {isGraphic &&
        resources?.graphicsDestinations &&
        resources.graphicsDestinations.length > 0 && (
          <DropdownWrapper role="presentation" onClick={openLowerthirdSelectMenu}>
            <LowerthirdDropdown
              isOpen={isLowerthirdSelectOpen}
              items={resources.graphicsDestinations}
              onChange={handleLowerthirdSelection}
              onClose={closeLowerthirdSelectMenu}
              onOpen={openLowerthirdSelectMenu}
              selectedValue={
                !graphicsDestination
                  ? resources.graphicsDestinations.find((r) => r.type === 'default').value
                  : graphicsDestination
              }
            />
          </DropdownWrapper>
        )}
    </RootWrapper>
  );
};

CharacterGraphics.propTypes = {
  resources: PropTypes.shape(PropTypes.object),
};

export default memo(CharacterGraphics);
