import { useCallback, useContext, useMemo, useState } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { Reference, useApolloClient, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/systemicons/email.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/systemicons/HeaderNavbar/chat_resting.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/systemicons/settings_on.svg';
import { ReactComponent as SpaceIcon } from 'assets/icons/systemicons/spaces_on.svg';
import Avatar from 'components/avatar/Avatar';
import { Button, IconButton } from 'components/buttons';
import EditableText from 'components/text/EditableText';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import UserCtx from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useCreateConvo from 'hooks/useCreateConvo';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useImageUpload, { type UploadProps } from 'hooks/useImageUpload';
import useImageUrl from 'hooks/useImageUrl';
import useShareStory from 'hooks/useShareStory';
import { HStack, VStack } from 'layouts/box/Box';
import UPDATE_MEMBER from 'operations/mutations/updateMember';
import { useSettingsOpen } from 'screens/main/components/header/navbar/settings/atomsTs';
import { useDepartments, useTeams } from 'store/members';
import { useIsMessageHubOpen, useSelectedConversationId } from 'store/messageHub';
import { MemberTypeEnum, Metadata } from 'types/graphqlTypes';
import { User } from 'types/members';
import dndTypes from 'utils/dndTypes';
import getInitials from 'utils/getInitials';
import { getPhotoUrl } from 'utils/member/member-utils';
import { uploadToS3 } from 'utils/s3Utils';

import DeleteMember from './DeleteMember';

const Container = styled(HStack)`
  position: relative;
  width: 100%;
  height: 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
`;

const BlurBackground = styled('div')`
  position: absolute;
  background: ${({ theme }) => theme.palette.dina.avatarBackdrop};
  backdrop-filter: blur(35px);
  inset: 0;
`;

const AvatarWrapper = styled('div')`
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  button {
    transition: height 100ms;
    position: absolute;
    bottom: 0;
    height: 0;
    padding: 0;
    border: none;
    border-radius: 0px;
  }
  :hover {
    button {
      height: 24px;
      padding-bottom: 4px;
    }
  }
`;

const getBackgroundImage = (title: string, url?: string) =>
  url
    ? `url(${url})`
    : `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100%' width='100%'><text x='10' y='155' fill='lightcoral' font-size='200' font-weight='700'>${getInitials(
        title,
      )}</text></svg>"`;

interface Props {
  member: User;
  userCount?: number;
  onClose: () => void;
}

function Header({ member, userCount, onClose }: Readonly<Props>) {
  const { errorToast } = useToast();
  const [assignMemberToStory] = useShareStory();
  const { mId } = useContext(UserCtx);

  const client = useApolloClient();
  const { navigateTo } = useDinaNavigate();
  const { createConvo } = useCreateConvo();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [, setSelectedConvoId] = useSelectedConversationId();
  const [, setSettingsOpen] = useSettingsOpen();
  const [updateMember] = useMutation<{ updateMember: User }>(UPDATE_MEMBER);
  const [teams, setTeams] = useTeams();
  const [departments, setDepartments] = useDepartments();

  const [uploadedAvatar, setUploadedAvatar] = useState<string | null>(null);

  const image = useImageUrl(member.mAvatarKey ?? '') ?? '';

  const [checkUserRight] = useCheckUserRight();
  const canDeleteContact = checkUserRight('folder', 'delete-contacts');

  const updateTeamsAndDepartments = (updatedMember: User) => {
    if (member.mType === MemberTypeEnum.Team) {
      const newTeams = teams.map((team) => {
        if (team.mId === member.mId) return updatedMember;
        return team;
      });
      setTeams(newTeams);
      return;
    }
    const newDepts = departments.map((dept) => {
      if (dept.mId === member.mId) return updatedMember;
      return dept;
    });
    setDepartments(newDepts);
  };

  const updateAvatarUrl = async (imageId: string) => {
    await updateMember({
      variables: {
        input: {
          mId: member.mId,
          mAvatarKey: imageId,
        },
      },
      onCompleted: (updated) => {
        updateTeamsAndDepartments(updated.updateMember);
      },
    });
  };

  const updateAvatar = async (imageFile: File, imageUrl: string) => {
    const photoUrl = getPhotoUrl();
    await uploadToS3(photoUrl, imageFile);
    await updateAvatarUrl(photoUrl);

    client.cache.modify({
      id: client.cache.identify(member.mId as unknown as Reference),
      fields: {
        mAvatarUrl: () => imageUrl,
        mAvatarKey: () => photoUrl,
      },
    });
  };

  const updateTitle = async (title: string) => {
    await updateMember({
      variables: {
        input: {
          mId: member.mId,
          mTitle: title,
        },
      },
      onCompleted: (updated) => {
        updateTeamsAndDepartments(updated.updateMember);
      },
    });
  };

  const onImageLoad = (imageFile: File | UploadProps[]) => {
    const imageSrc = URL.createObjectURL(imageFile as File);

    setUploadedAvatar(imageSrc);
    void updateAvatar(imageFile as File, imageSrc);
  };

  const captureImage = useImageUpload({ onImageLoad });

  const isCurrentUser = useMemo(() => member.mId === mId, [mId, member.mId]);
  const isUser = useMemo(() => member.mType === MemberTypeEnum.User, [member.mType]);
  const isContact = useMemo(() => member.mType === MemberTypeEnum.Contact, [member.mType]);
  const isChannel = useMemo(() => ['team', 'department'].includes(member?.mType), [member.mType]);

  const openSpace = useCallback(
    () => navigateTo('space', `${member.mId}-${member.mType}`),
    [member.mId, member.mType, navigateTo],
  );

  const openChat = useCallback(async () => {
    await createConvo(member, (convo) => {
      setSelectedConvoId(convo?.mId);
      setIsMessageHubOpen(true);
    });
  }, [createConvo, member, setIsMessageHubOpen, setSelectedConvoId]);

  const email = useMemo(
    () =>
      ((JSON.parse(member?.metadata && member.metadata !== '' ? member.metadata : '{}') as Metadata)
        ?.email as string) ?? member?.mProperties?.email,
    [member?.metadata, member?.mProperties?.email],
  );

  const sendEmail = useCallback(() => window.location.assign(`mailto: ${email}`), [email]);

  const [, dragRef] = useDrag({
    type: dndTypes.MEMBER,
    item: () => member,
    end: (_, monitor: DragSourceMonitor<User, { id: string }>) => {
      if (!monitor.didDrop()) return;
      if (typeof monitor?.getDropResult()?.id === 'string') {
        const dropResId = monitor?.getDropResult()?.id;
        if (dropResId)
          assignMemberToStory(dropResId, member.mId, member.mType).catch((err) => errorToast(err));
      }
    },
  });

  return (
    <Container style={{ backgroundImage: getBackgroundImage(member.mTitle, member.mAvatarUrl) }}>
      <BlurBackground />
      <VStack width="100%">
        <HStack padding="0 8px 0" gap="4px" justifyContent="end" width="100%">
          {isCurrentUser && (
            <IconButton
              usage="text"
              size={24}
              iconSize={18}
              title="Edit Profile"
              onClick={() => setSettingsOpen(true)}
            >
              <SettingsIcon />
            </IconButton>
          )}
          {isChannel && (
            <IconButton usage="text" size={24} iconSize={18} title="Open Space" onClick={openSpace}>
              <SpaceIcon />
            </IconButton>
          )}
          {!isCurrentUser && !isContact && (
            <IconButton usage="text" size={24} iconSize={20} title="Open Chat" onClick={openChat}>
              <ChatIcon />
            </IconButton>
          )}
          {!isCurrentUser && email && (
            <IconButton usage="text" size={24} iconSize={20} title="Send Email" onClick={sendEmail}>
              <EmailIcon />
            </IconButton>
          )}
          {!isUser && canDeleteContact && <DeleteMember member={member} />}
          <IconButton
            variant="discreet"
            usage="text"
            round
            title="Close"
            height={24}
            width={24}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </HStack>
        <HStack width="100%" position="relative" padding="12px" gap="8px" ref={dragRef}>
          <AvatarWrapper>
            <Avatar size={64} src={uploadedAvatar ?? image} title={member.mTitle} />
            {member.mType !== MemberTypeEnum.User && (
              <Button variant="outlined" height={24} onClick={captureImage}>
                Update
              </Button>
            )}
          </AvatarWrapper>
          <VStack flex="1" height="100%">
            {isChannel ? (
              <EditableText
                value={member.mTitle}
                onUpdate={updateTitle}
                variant="h6"
                color="whiteHighEmphasis"
                borderColor="whiteWatermark"
                truncate
              />
            ) : (
              <Text variant="h6" truncate>
                {member.mTitle}
              </Text>
            )}
            <Text variant="listItemLabel">{capitalize(member.mType)}</Text>
            {userCount && <Text variant="listItemLabel">{`${userCount} Members`}</Text>}
          </VStack>
        </HStack>
      </VStack>
    </Container>
  );
}

export default Header;
