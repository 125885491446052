import { useCallback, useEffect, useState } from 'react';
import { omit } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import useUpdateMetadata from 'api/useUpdateMetadata';
import useToast from 'components/toast/useToast';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { Mdf, MemberType, ViewTypes } from 'types/graphqlTypes';
import { getDefaultValues } from 'utils/mdf/utils';

import useMdfErrorMap, { ErrorMap, getErrorMap } from './useMdfErrorMap';

export type MemberAutoSave = Pick<MemberType, 'mType' | 'mId' | 'mRefId'>;

const canSave = (mdToSave: Metadata, errorMap: ErrorMap) => {
  for (const key of Object.keys(mdToSave)) {
    if (errorMap[key]) return false;
  }
  return true;
};

/**
 * Takes care of handling business logic surrounding metadata workflows.
 *
 * 1) Injects default values on load if values are missing
 * 2) Keeps track of the errors related to fields in a central place
 * 3) Supports auto saving partial metadata changes if a member is provided
 *    with built-in support to avoid auto save if an error is present
 *
 */
const useMetadata = (
  mdf: Mdf | undefined,
  md: Metadata = {},
  view: ViewTypes = 'default',
  memberToAutoSave?: MemberAutoSave,
  deps?: unknown,
) => {
  const { errorToast } = useToast();
  const [prevMdf, setPrevMdf] = useState(mdf);
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const updateMetadata = useUpdateMetadata();
  const { errorMap, validFieldMap, errorTooltip } = useMdfErrorMap(mdf, metadata, view);
  const { mdfsSeparated } = useGetMdfs();

  useEffect(() => {
    if (mdf) {
      const defaultValues = getDefaultValues(md, mdf, mdfsSeparated.subTypes);
      setMetadata({
        ...md,
        ...defaultValues,
      });
    } else {
      setMetadata({});
    }
  }, [deps]);

  if (mdf !== prevMdf) {
    setPrevMdf(mdf);
    if (mdf) {
      const defaultValues = getDefaultValues(md, mdf, mdfsSeparated.subTypes);
      setMetadata({
        ...md,
        ...defaultValues,
      });
    } else {
      setMetadata({});
    }
  }

  const updateFieldValues = useCallback(
    (val: NewFieldValue[]) => {
      const updatedMd: Metadata = {};
      for (const update of val) {
        updatedMd[update.fieldId] = update.value;
      }
      const initMdata = metadata;
      const updatedMdata = { ...initMdata, ...updatedMd };
      setMetadata(updatedMdata);
      if (memberToAutoSave) {
        const updatedErrorMap = getErrorMap(validFieldMap, { ...metadata, ...updatedMd });
        if (
          canSave(updatedMd, updatedErrorMap) &&
          memberToAutoSave.mId &&
          memberToAutoSave.mRefId &&
          memberToAutoSave.mType
        ) {
          updateMetadata(
            memberToAutoSave.mId,
            memberToAutoSave.mRefId,
            updatedMd,
            md,
            memberToAutoSave.mType,
            mdf?.id,
          ).catch(errorToast);
        } else if (!canSave(updatedMd, updatedErrorMap)) {
          errorToast(
            new Error('Required fileds can not be empty'),
            'Required fileds can not be empty',
          );
          setMetadata(initMdata);
        }
      }
    },
    [memberToAutoSave, validFieldMap, metadata, updateMetadata, md, mdf?.id, errorToast],
  );

  const removeValue = useCallback(
    (fieldId: string) => {
      setMetadata((prevValue) => {
        return {
          ...omit(prevValue, fieldId),
        };
      });
    },
    [setMetadata],
  );

  return {
    metadata: metadata ?? {},
    errorMap,
    errorTooltip,
    updateFieldValues,
    removeValue,
  };
};

export default useMetadata;
