/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable max-len */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { capitalize, keyBy } from 'lodash';

import { blockMdfLabel, supportsMdf } from 'api/mdf/useGetMdf';
import { MdfsGrouped } from 'api/mdf/useGetMdfs';
import Radio from 'components/buttons/radioButton';
import Dialog from 'components/dialogs/DialogBuilder';
import Infobar from 'components/infobar/Infobar';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { HStack, VStack } from 'layouts/box/Box';
import { Alternative } from 'types/graphqlTypes';

import CreateSchemaDropdown from './CreateSchemaDropdown';

const SchemaTypes = ['custom', 'subtype', 'instance', 'block'] as const;
const SchemaTypeLabel: Record<SchemaType, string> = {
  custom: 'Custom schema',
  instance: 'Instance schema',
  subtype: 'Sub type',
  block: 'Editor blocks',
};
const SchemaTypeInfo: Record<SchemaType, string> = {
  custom:
    'A custom schema can be connected to order forms, planning items, and other configurations.',
  instance:
    'Add a new schema for a specific instance platform. If a schema for a platform already exists, it will not appear here.',
  subtype:
    'A sub type is a set of fields, that can be configured that will appear based on a user choice.',
  block:
    'Configure custom fields on blocks of editor content. Currently only a subset of blocks on CMS editor are supported.',
};

interface Props {
  open: boolean;
  creatingMdf: boolean;
  schemaType: SchemaType;
  mdfsSeparated: MdfsGrouped;
  canConfigureBlocks: boolean;
  setSchemaType: (val: SchemaType) => void;
  setOpen: (val: boolean) => void;
  onCreate: (val: { label: string; schemaType: SchemaType; instanceId?: string }) => void;
}

export type SchemaType = (typeof SchemaTypes)[number];

// This determines what admins can configure for blocks.
const availableBlocks: Alternative[] = supportsMdf.map((v) => {
  return {
    id: `block-${v}`,
    label: blockMdfLabel[v],
    value: `block-${v}`,
  };
});

export default function CreateSchemaDialog({
  mdfsSeparated,
  canConfigureBlocks,
  open,
  setOpen,
  creatingMdf,
  onCreate,
  schemaType,
  setSchemaType,
}: Readonly<Props>) {
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const { platformVariants } = useGetPlatforms();
  const [label, setLabel] = useState('');
  const [selectedInstance, setSelectedInstance] = useState<Alternative | null>(null);
  const [selectedBlock, setSelectedBlock] = useState<Alternative | null>(null);

  const derivedInstanceId = useMemo(() => {
    return schemaType === 'instance' ? selectedInstance?.id ?? null : null;
  }, [selectedInstance, schemaType]);

  const derivedLabel = useMemo(() => {
    if (schemaType === 'block') return selectedBlock?.label ?? '';
    if (derivedInstanceId && platformVariants[derivedInstanceId]) {
      if (derivedInstanceId === 'linear') return 'Linear';
      if (derivedInstanceId === 'linear-audio') return 'Audio';
      return capitalize(platformVariants[derivedInstanceId]?.title ?? label);
    }
    return label;
  }, [label, derivedInstanceId, platformVariants, schemaType, selectedBlock]);

  const onConfirm = useCallback(() => {
    if (schemaType === 'block' && selectedBlock) {
      onCreate({
        label: derivedLabel,
        schemaType,
        instanceId: selectedBlock.id,
      });
    } else if (schemaType === 'instance' && derivedInstanceId) {
      onCreate({
        label: derivedLabel,
        schemaType,
        instanceId: derivedInstanceId,
      });
    } else if (schemaType !== 'instance') {
      onCreate({
        label: derivedLabel,
        schemaType,
      });
    }
  }, [derivedLabel, derivedInstanceId, schemaType]);

  const disabledConfirm = derivedLabel.length < 1 || creatingMdf;
  const onTextInputKeyUp = useCallback(
    (ev: React.KeyboardEvent<HTMLDivElement>) => {
      if (ev.key === 'Enter' && !disabledConfirm) {
        onConfirm();
      } else if (ev.key === 'Escape') {
        setOpen(false);
      }
    },
    [onConfirm, setOpen],
  );

  useEffect(() => {
    if (!open) {
      setLabel('');
      setSchemaType('custom');
      setSelectedInstance(null);
    }
  }, [open]);

  useEffect(() => {
    setSelectedBlock(null);
    setSelectedInstance(null);
  }, [schemaType]);

  const existingInstanceMdfsById = useMemo(() => {
    return keyBy(mdfsSeparated.instances, (mdf) => mdf.id);
  }, [mdfsSeparated]);

  const existingBlockMdfsById = useMemo(() => {
    return keyBy(mdfsSeparated.blocks, (mdf) => mdf.id);
  }, [mdfsSeparated]);

  const allInstanceOptions = useMemo(() => {
    const options: Alternative[] = [];
    for (const platform in platformVariants) {
      const variant = platformVariants[platform];
      options.push({
        id: variant.id,
        value: variant.id,
        icon: variant.icon,
        label: variant.title,
      });
    }
    return options;
  }, [platformVariants]);

  const instanceOptions = useMemo(() => {
    return allInstanceOptions.filter((o) => existingInstanceMdfsById[o.id] === undefined);
  }, [allInstanceOptions, existingInstanceMdfsById]);

  const blockOptions = useMemo(() => {
    return availableBlocks.filter((o) => existingBlockMdfsById[o.id] === undefined);
  }, [existingBlockMdfsById]);

  const menuOptions = useMemo(() => {
    if (schemaType === 'instance') return instanceOptions;
    return blockOptions;
  }, [instanceOptions, blockOptions, schemaType]);

  const onSelect = (val: Alternative) => {
    if (schemaType === 'instance') {
      setSelectedInstance(val);
    } else {
      setSelectedBlock(val);
    }
  };

  const selected = useMemo(() => {
    return schemaType === 'instance' ? selectedInstance : selectedBlock;
  }, [selectedInstance, selectedBlock, schemaType]);

  const availableTypes = useMemo(() => {
    if (canConfigureBlocks) return SchemaTypes;
    return SchemaTypes.filter((s) => s !== 'block');
  }, [canConfigureBlocks]);

  const isDropdownVisible = ['instance', 'block'].includes(schemaType);
  const wasOpen = useRef(open);
  useEffect(() => {
    if (open === wasOpen.current) return;
    wasOpen.current = open;
    if (open) {
      setTimeout(() => {
        const input = inputContainerRef.current?.getElementsByTagName('input')?.[0];
        input?.focus();
      }, 0);
    }
  }, [open]);
  return (
    <Dialog open={open} onClose={() => setOpen(false)} modal>
      <Dialog.Header>Create new schema</Dialog.Header>
      <Dialog.Body>
        <VStack container gap="4px" justifyContent="start" alignItems="start">
          {availableTypes.map((type) => (
            <HStack container key={type} gap="4px">
              <Radio selected={schemaType === type} onClick={() => setSchemaType(type)} size={16} />
              <Text
                variant="caption"
                color={schemaType === type ? 'highEmphasis' : 'mediumEmphasis'}
                onClick={() => setSchemaType(type)}
                style={{ cursor: 'pointer' }}
              >
                {SchemaTypeLabel[type]}
              </Text>
            </HStack>
          ))}
        </VStack>
        <VStack ref={inputContainerRef} gap="4px" margin="10px 0">
          {isDropdownVisible ? (
            <CreateSchemaDropdown
              schemaType={schemaType}
              placeholder={schemaType === 'block' ? 'Select block type' : 'Select instance'}
              menuOptions={menuOptions}
              selectedOption={selected}
              onSelect={onSelect}
            />
          ) : (
            <StyledTextField
              error={derivedLabel.length < 1}
              helperText={derivedLabel.length < 1 ? 'Label required' : ''}
              variant="filled"
              fullWidth
              placeholder="Schema label"
              value={derivedLabel}
              onChange={(event) => setLabel(event.target.value)}
              onKeyUp={onTextInputKeyUp}
            />
          )}
        </VStack>
        <Infobar>{SchemaTypeInfo[schemaType]}</Infobar>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          onConfirm={onConfirm}
          label="Confirm"
          disabled={derivedLabel.length < 1 || creatingMdf}
          loading={creatingMdf}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
