/* eslint-disable import/prefer-default-export */
import { atom, useAtom } from 'jotai';
import MainSettingsView from 'utils/settings/schemas/mainSettingsView';
import filterVisibleCategories from './utils/filterVisibleCategories';
import GROUP_SETTING_TYPES from './utils/groupSettingTypes';
import SETTING_TYPES from './utils/settingTypes';
import USER_SETTING_TYPES from './utils/userSettingTypes';

/** Selected group name */
const selectedGroupNameAtom = atom();
export const useSelectedGroupName = () => useAtom(selectedGroupNameAtom);

/** Selected item from left menu for system settings */
const categories = filterVisibleCategories(MainSettingsView);
const title = categories?.[0]?.title;
const selectedMenuSystemSettingsAtom = atom(title);
export const useSelectedMenuSystemSettings = () => useAtom(selectedMenuSystemSettingsAtom);

/** State of confirm dialog on close */
const isOpenConfirmDialogAtom = atom(false);
export const useIsOpenConfirmDialog = () => useAtom(isOpenConfirmDialogAtom);

/** State of group open/close in left menu */
const isGroupOpenAtom = atom(false);
export const useIsGroupOpen = () => useAtom(isGroupOpenAtom);

/** Selected settings category (group header) */
const selectedCategoryAtom = atom(SETTING_TYPES.GENERAL);
export const useSelectedCategory = () => useAtom(selectedCategoryAtom);

/** Changed metadata fields in system settings */
const changedMetadataFieldAtom = atom([]);
export const useChangedMetadataField = () => useAtom(changedMetadataFieldAtom);

/** Changed rundownGridviews fields in system settings */
const changedRundownGridViews = atom([]);
export const useChangedRundownGridViews = () => useAtom(changedRundownGridViews);

/** Changed metadata fields settings rows */
const changedRundownGridViewsColumns = atom([]);
export const useChangedRundownGridViewColumns = () => useAtom(changedRundownGridViewsColumns);

/** Metadata fields in system settings */
const metadataFieldsAtom = atom([]);
export const useMetadataFields = () => useAtom(metadataFieldsAtom);

/** Link fields in system settings */
const linkFieldsAtom = atom([]);
export const useLinkFields = () => useAtom(linkFieldsAtom);

/** Id of new metadata field */
const newMetadataFieldIdAtom = atom('');
export const useNewMetadataFieldId = () => useAtom(newMetadataFieldIdAtom);

/** Changed metadata fields settings rows */
const changedInstanceFieldsAtom = atom([]);
export const useChangedInstanceFields = () => useAtom(changedInstanceFieldsAtom);

/** Changed link fields in system settings */
const changedLinkFieldsAtom = atom([]);
export const useChangedLinkFields = () => useAtom(changedLinkFieldsAtom);

const newLinkFieldIdAtom = atom('');
export const useNewLinkFieldId = () => useAtom(newLinkFieldIdAtom);

/** Selected user setting */
const selectedUserSettingAtom = atom(USER_SETTING_TYPES.PROFILE);
export const useSelectedUserSettingAtom = () => useAtom(selectedUserSettingAtom);

/** Selected menu category from group tab */
const selectedGroupCategoryAtom = atom(GROUP_SETTING_TYPES.GROUP);
export const useSelectedGroupCategoryAtom = () => useAtom(selectedGroupCategoryAtom);
