/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable max-len */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'lodash';

import Dialog from 'components/dialogs/DialogBuilder';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import { Box } from 'layouts/box/Box';
import { OptionList } from 'types/graphqlTypes';

import { OptionListChangeInfoMap } from '../../atomsTs';
import { programmaticIdRegex } from '../integrations/EditActions';
import { createOptionListLabelValidator } from '../mdf/optionListsHelpers';

interface Props {
  open: boolean;
  creating: boolean;
  existingOptionListsAndTrees: readonly OptionList[];
  changedOptionLists: OptionListChangeInfoMap;
  optionListType: 'choice' | 'treechoice';
  setOpen: (val: boolean) => void;
  onCreate: (val: { label: string; id: string; optionListType: 'choice' | 'treechoice' }) => void;
}

function getOptionListTypeName(optionListType: 'choice' | 'treechoice') {
  return optionListType === 'choice' ? 'option list' : 'option tree';
}

export default function CreateOptionListDialog({
  existingOptionListsAndTrees,
  changedOptionLists,
  creating,
  open,
  optionListType,
  setOpen,
  onCreate,
}: Readonly<Props>) {
  const [label, setLabel] = useState('');
  const [id, setId] = useState('');
  const title = `Create new ${getOptionListTypeName(optionListType)}`;

  const byId = useMemo(() => {
    return keyBy(existingOptionListsAndTrees, (e) => e.id);
  }, [existingOptionListsAndTrees]);

  const onConfirm = useCallback(() => {
    if (label.length > 0 && id.length > 0) {
      onCreate({ label, id, optionListType });
    }
  }, [label, id, optionListType]);

  const validateLabel = useMemo(
    () =>
      createOptionListLabelValidator(
        existingOptionListsAndTrees,
        changedOptionLists,
        optionListType === 'choice' ? 'list' : 'tree',
        '',
      ),
    [existingOptionListsAndTrees, changedOptionLists, optionListType],
  );

  const labelErrorMessage = useMemo(() => {
    const validationResult = validateLabel(label) || ' ';
    return validationResult === true ? '' : validationResult;
  }, [label, validateLabel]);

  const idErrorMessage = useMemo(() => {
    if (id.length === 0) return 'Required value';
    const existing = byId[id];
    if (existing)
      return `An ${getOptionListTypeName(
        existing.optionListType,
      )} with identifier ${id} already exists`;
    if (!programmaticIdRegex.test(id)) return 'Only alphanumeric characters are allowed';
    return '';
  }, [id, byId]);

  const disabledConfirm = idErrorMessage.length > 0 || labelErrorMessage.length > 0 || creating;
  const onKeyUp = useCallback(
    (ev: React.KeyboardEvent<HTMLDivElement>) => {
      if (ev.key === 'Enter' && !disabledConfirm) {
        onConfirm();
      } else if (ev.key === 'Escape') {
        setOpen(false);
      }
    },
    [onConfirm, setOpen],
  );
  useEffect(() => {
    if (!open) {
      setLabel('');
      setId('');
    }
  }, [open, setLabel, setId]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Body>
        <Box margin="10px 0">
          <StyledTextField
            error={idErrorMessage.length > 0}
            helperText={idErrorMessage}
            autoFocus
            variant="filled"
            fullWidth
            placeholder="Programmatic identifier"
            value={id}
            onChange={(event) => setId(event.target.value)}
            onKeyUp={onKeyUp}
          />
        </Box>
        <Box margin="10px 0">
          <StyledTextField
            error={labelErrorMessage.length > 0}
            helperText={labelErrorMessage.trim()}
            variant="filled"
            fullWidth
            placeholder="User visible label"
            value={label}
            onChange={(event) => setLabel(event.target.value)}
            onKeyUp={onKeyUp}
          />
        </Box>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          onConfirm={onConfirm}
          label="Confirm"
          disabled={disabledConfirm}
          loading={creating}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
