import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import Text from 'components/text';
import Tooltip from 'components/tooltip';
import useCopyText from 'hooks/useCopyText';
import { Box, VStack } from 'layouts/box/Box';
import is24HourFormat from 'utils/dateTime/is24HourFormat';

const ClockContainer = styled('div')<{ $copyOnClick: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  height: 100%;
  width: ${is24HourFormat() ? '64px' : '94px'};
  background-color: transparent;
  cursor: ${({ $copyOnClick }) => ($copyOnClick ? 'pointer' : 'text')};
`;

interface Props {
  copyOnClick: boolean;
}

const DigitalClock = ({ copyOnClick }: Props) => {
  const [time, setTime] = useState(new Date());
  const { onCopy } = useCopyText();

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const timezone = useMemo((): string => {
    const options: Intl.DateTimeFormatOptions = { timeZoneName: 'short' };
    return (
      new Intl.DateTimeFormat(navigator.language, options)
        .formatToParts(new Date())
        .find((part) => part.type === 'timeZoneName')?.value ?? ''
    );
  }, []);

  const formatTime = useCallback((date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: !is24HourFormat(),
    };
    return date.toLocaleTimeString(navigator.language, options);
  }, []);

  const onClick = () => {
    if (!copyOnClick) return;
    onCopy(formatTime(time), 'Timestamp copied to clipboard').then(
      () => {},
      () => {},
    );
  };

  return (
    <Tooltip
      title={
        <Box
          container
          flexDirection="column"
          gap="4px"
          width={is24HourFormat() ? '108px' : '160px'}
          alignItems="flex-end"
        >
          <Text variant="h5" color="whiteHighEmphasis">
            {formatTime(time)}
          </Text>
          <VStack style={{ alignSelf: 'flex-end' }}>
            <Text variant="caption" color="whiteMediumEmphasis">
              Local time
            </Text>
            <Text variant="caption" color="whiteMediumEmphasis">
              ({timezone})
            </Text>
          </VStack>
        </Box>
      }
    >
      <ClockContainer $copyOnClick={copyOnClick} onClick={onClick}>
        <Text variant="listItemLabelMedium" color="whiteMediumEmphasis">
          {formatTime(time)}
        </Text>
      </ClockContainer>
    </Tooltip>
  );
};

export default DigitalClock;
