import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import intersectionBy from 'lodash/intersectionBy';

import Assignees from 'components/assignees/Assignees';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';
import useShareStory from 'hooks/useShareStory';
import { useAllMembers, useMembersDialog } from 'store';
import { AssignedMember, User } from 'types/members';

const Container = styled('div')`
  display: flex;
  align-items: center;
`;

function Share({
  storyId,
  assignedMembers,
}: Readonly<{
  storyId: string;
  assignedMembers: AssignedMember[];
}>) {
  const notificationRef = useRef<HTMLDivElement>(null);

  const [allMembers] = useAllMembers();
  const [, setGetMembers] = useMembersDialog();
  const [, shareStoryWith] = useShareStory();

  const [mAssignedMembers, setMAssignedMembers] = useState<AssignedMember[] | User[]>(
    assignedMembers ?? [],
  );

  useEffect(() => setMAssignedMembers(assignedMembers ?? []), [assignedMembers]);

  const members = intersectionBy(allMembers, mAssignedMembers, 'mId');

  const openDialog = (): void => {
    setGetMembers({
      open: true,
      removeTooltipText: 'Remove',
      dialogTitle: 'Assign to Users/Teams/Departments',
      subHeader: 'Assigned to this story:',
      startWith: members,
      showMessage: true,
      onOk: (newMembers: AssignedMember[], messageAssign: string): void => {
        if (newMembers) {
          setMAssignedMembers(newMembers);
          void shareStoryWith(storyId, newMembers, members, null, messageAssign, true);
        }
      },
    });
  };

  return (
    <Container ref={notificationRef}>
      <NotificationPopup
        position="bottom"
        anchor={notificationRef}
        id={notificationIdentifiers.ShareStoryButton}
        title="Sharing made easier"
        text="Share effortlessly with individuals, teams, and departments. Just 
              click an avatar or the sharing button to open the sharing dialog, then 
              start typing. You can also add a custom message for the recipients."
      />
      <Assignees
        dark
        members={mAssignedMembers}
        size={30}
        maxAvatarToShow={3}
        onClick={openDialog}
      />
    </Container>
  );
}

export default Share;
