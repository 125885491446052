import styled from '@emotion/styled';

import { VerticalResizeHandle } from 'features/layouts/ResizableHandlers';
import { HStack } from 'layouts/box/Box';
import { CommandItem } from 'lib/command';
import transientOptions from 'theme/helpers';

import { ToolbarIcons } from './command-constants';

export const FeedDate = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
`;

export const SearchTotal = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
  white-space: pre;
  padding-right: 6px;
`;

export const HeaderWrapper = styled(HStack, transientOptions)<{ $dark: boolean }>`
  justify-content: start;
  width: 100%;
  min-height: 48px;
  background: ${({ $dark, theme }) => ($dark ? theme.palette.dina.surfacePaper : 'inherit')};
  [cmdk-input-wrapper] {
    border-bottom: none;
  }
`;

export const StyledVerticalResizeHandle = styled(VerticalResizeHandle)`
  height: 100vh;
  background: ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const FeedFooter = styled(HStack)`
  ${({ theme }) => theme.typography.dina.caption};
  align-content: center;
  justify-content: start;
  gap: 4px;
`;

export const CommandHeader = styled(HStack)`
  ${({ theme }) => theme.typography.dina.caption};
  padding-inline: 8px;
  height: 24px;
  width: 100%;
  background: ${({ theme }) => theme.palette.dina.headerFooterOverlay};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const FuzzyIcon = styled(ToolbarIcons.Fuzzy, transientOptions)<{ $active?: boolean }>`
  cursor: pointer;
  &:active {
    margin-top: 1px;
  }
  & path {
    fill-opacity: 1 !important;
    fill: ${({ theme, $active }) => ($active ? theme.palette.dina.statusWarning : '')} !important;
  }
`;

export const ItemInner = styled('div')`
  height: 100%;
  width: calc(100% - 32px);
  padding: 4px;
`;

export const ItemTitle = styled('div')`
  ${({ theme }) => theme.typography.dina.listItemLabelBold};
  margin-bottom: 4px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ItemDescription = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
  position: relative;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WrappedItem = styled(CommandItem)`
  display: flex;
  justify-content: space-between;
  padding: 4px;
  width: 100%;
  overflow: hidden;
  svg {
    width: 20px;
    height: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    path {
      fill-opacity: 1;
    }
  }
  &.search-item,
  &.feed {
    height: 84px;
    .search-icon {
      align-self: start;
      margin-top: 4px;
    }
  }

  &.asset {
    height: 64px;
    svg {
      top: 1px !important;
    }
  }
`;
