import { useCallback } from 'react';
import { Reference, useApolloClient, useMutation } from '@apollo/client';
import difference from 'lodash/difference';

import memberTypes from 'operations/memberTypes';
import UPDATE_CONTACT from 'operations/mutations/updateContact';
import UPDATE_MEMBER from 'operations/mutations/updateMember';
import UPDATE_MEMBERS from 'operations/mutations/updateMembers';
import GET_MEMBERS_OF from 'operations/queries/getMembersOf';
import { getMembersOfQuery } from 'operations/queryVariables';
import { useMembers } from 'store';
import { AssignedMember, User } from 'types';
import { Metadata } from 'types/forms/forms';
import { uploadToS3 } from 'utils/s3Utils';
import useLogger from 'utils/useLogger';

import { ChangedProfilePictureProps, ChangedTeamsOrDeptsTypes } from '../atomsTs';

interface UpdateContactType {
  updateContact: AssignedMember;
}

const getTitleFromMD = (metadata: Metadata) => {
  const { firstName = '', surname = '' } = metadata;
  return `${firstName as string} ${surname as string}`;
};

const useUpdateProfile = () => {
  const logger = useLogger('update-profile-hook');
  const [members, setMembers] = useMembers();
  const [updateContent] = useMutation<UpdateContactType>(UPDATE_CONTACT);
  const [updateMember] = useMutation<{ updateMember: User }>(UPDATE_MEMBER);
  const [updateMembersMutation] = useMutation(UPDATE_MEMBERS);
  const client = useApolloClient();

  const updateProfileMetadata = useCallback(
    async (mId: string, metadata: Metadata) => {
      const variables = {
        input: {
          mId,
          metadata: JSON.stringify(metadata),
          mTitle: getTitleFromMD(metadata),
        },
      };
      await updateContent({
        variables,
        update: (_proxy, updatedUser) => {
          const { updateContact } = updatedUser?.data ?? {};
          setMembers({
            ...members,
            user: members.user?.map((member) => {
              if (member?.mId === updateContact?.mId) {
                return updateContact;
              }
              return member;
            }),
          });
        },
      });
    },
    [members, setMembers, updateContent],
  );

  const updateAvatarKey = async (changedPicture: ChangedProfilePictureProps) => {
    await updateMember({
      variables: {
        input: {
          mId: changedPicture.mId,
          mAvatarKey: changedPicture.key,
        },
      },
    });
    client.cache.modify({
      id: client.cache.identify(changedPicture.mId as unknown as Reference),
      fields: {
        mAvatarKey: () => changedPicture.key,
      },
    });
  };

  const updateProfilePicture = useCallback(
    async (changedPicture: ChangedProfilePictureProps) => {
      try {
        await uploadToS3(changedPicture.key, changedPicture.file);
        if (changedPicture.mId) {
          await updateAvatarKey(changedPicture);
        }
      } catch (error) {
        logger.log(error);
      }
    },
    [logger],
  );

  const updateProfileTeamsOrDepts = useCallback(
    async (
      userId: string,
      resourceType: 'team' | 'department',
      changedMembers: ChangedTeamsOrDeptsTypes,
    ) => {
      const mType =
        resourceType === 'department' ? memberTypes.DEPARTMENT_USER : memberTypes.TEAM_USER;
      const existingIds = changedMembers.old?.map(({ mId }) => mId);
      const updatedIds = changedMembers.new.map(({ mId }) => mId);

      const addedIds = difference(updatedIds, existingIds);

      const newMembers = {
        members: addedIds.map((mId) => ({
          mId,
          mRefId: userId,
          mType,
        })),
      };

      const removedIds = difference(existingIds, updatedIds);
      const removedMembers = {
        members: removedIds.map((mId) => ({
          mId,
          mRefId: userId,
        })),
      };

      // Cache update for team and department
      try {
        await updateMembersMutation({
          variables: {
            newMembers,
            removedMembers,
          },
          update: (proxy) => {
            const newMembersWithType = changedMembers.new.map((member) => ({
              ...member,
              mType: resourceType,
            }));

            proxy.writeQuery({
              query: GET_MEMBERS_OF,
              variables: getMembersOfQuery(userId, mType),
              data: {
                getMembersOf: newMembersWithType,
              },
            });

            proxy.writeQuery({
              query: GET_MEMBERS_OF,
              variables: {
                input: {
                  membersOfType: mType,
                  mId: userId,
                },
              },
              data: {
                getMembersOf: newMembersWithType,
              },
            });
          },
        });
      } catch (error) {
        logger.log(error);
      }
    },
    [updateMembersMutation, logger],
  );

  return { updateProfileMetadata, updateProfilePicture, updateProfileTeamsOrDepts };
};

export default useUpdateProfile;
