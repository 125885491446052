import { getLayoutSettings } from 'types/forms/forms';
import { LayoutSettings, Mdf, MdfField, ViewTypes } from 'types/graphqlTypes';
import { uniqueMdfFilter } from 'utils/mdf/utils';

export type MappedMdfField = MdfField & { formId: string; settings: LayoutSettings };
export type MdfFieldMappings = Record<string, MappedMdfField[]>;

export const getMdfFieldMappings = (
  mdfs: Record<string, Mdf | undefined>,
  view: ViewTypes = 'story_create',
): MdfFieldMappings => {
  const fieldMap: MdfFieldMappings = {};
  for (const mdf of Object.values(mdfs).filter(uniqueMdfFilter)) {
    for (const field of mdf.fields) {
      const settings = getLayoutSettings(mdf, field, view);
      const fieldAndSettings: MappedMdfField = { ...field, formId: mdf.id, settings };
      if (fieldMap[field.fieldId]) {
        fieldMap[field.fieldId].push(fieldAndSettings);
      } else {
        fieldMap[field.fieldId] = [fieldAndSettings];
      }
    }
  }
  return fieldMap;
};
