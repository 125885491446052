import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import isNull from 'lodash/isNull';

import { initialValues } from 'components/editor/constants';
import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import variants from 'components/editor/constants/types/editorVariants';
import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import { useNotesMolecule } from 'features/notes/store';
import useContentResolver from 'hooks/useContentResolver';
import useCreateAsset, { AssetInput } from 'hooks/useCreateAsset';
import { useDisableMouseEvents } from 'store';
import { Asset, EditorValue } from 'types';
import { getAssetData, getFileAssetData } from 'utils/assetData';
import { getScopeFromLockedId, getUserIdFromLockedId } from 'utils/lock/lockTokenV2';
import useLogger from 'utils/useLogger';

import { useNoteMolecule } from '../store';

const DEBOUNCE_DELAY = 12000; // 12 seconds
const PERIODIC_SAVE_INTERVAL = 5 * 60 * 1000; // 5 minutes

type UpdateInput =
  | { type: ActionTypesEnum.CHANGE; payload: EditorValue }
  | {
      type: ActionTypesEnum.COMMIT_UPDATE;
      payload: EditorValue;
      commitFor: 'asset' | 'userInitiated';
    }
  | { type: ActionTypesEnum.CREATE_ASSET; payload: { asset: Asset } }
  | { type: ActionTypesEnum.ASSET_INSERT; payload: { file: File } };

const defaultContentValue = initialValues(variants.NOTES, undefined, undefined, false);

const useNoteEditor = () => {
  const {
    baseAtom,
    lockContentAtom,
    unlockNoteAtom,
    saveContentAtom,
    updateContentAtom,
    editorValueRef,
    saveTimeoutRef,
    periodicSaveRef,
  } = useNoteMolecule();
  const { restoreVersionFnRef, currentEditingScope } = useNotesMolecule();

  const { note, isLocked, lockedBy, isLoading, isSaving, content, canUpdate } =
    useAtomValue(baseAtom);

  const { mRefId: noteId, mContentKey, mStoryId } = note ?? {};
  const { mId: currentUserId } = useContext(UserContext);

  const [createAssetMutation] = useCreateAsset();
  const { errorToast } = useToast();
  const { log } = useLogger('useNoteEditor');

  const lockNote = useSetAtom(lockContentAtom);
  const unlockNote = useSetAtom(unlockNoteAtom);
  const updateContent = useSetAtom(updateContentAtom);
  const saveContent = useSetAtom(saveContentAtom);

  const [, setDisableMouseEvents] = useDisableMouseEvents();
  const [shouldResetSelection, setShouldResetSelection] = useState(false);

  const { lockedByCurrentUser, lockedInSameScope } = useMemo(
    () => ({
      lockedByCurrentUser: getUserIdFromLockedId(lockedBy) === currentUserId,
      lockedInSameScope: getScopeFromLockedId(lockedBy) === currentEditingScope,
    }),
    [currentUserId, lockedBy, currentEditingScope],
  );

  const shouldSkipFetching = useMemo(() => {
    return isLocked && lockedByCurrentUser && lockedInSameScope;
  }, [isLocked, lockedByCurrentUser, lockedInSameScope]);

  const {
    data: s3Data,
    loading: contentLoading,
    refetch,
  } = useContentResolver(mContentKey ?? '', !mContentKey || shouldSkipFetching, true);

  const disableResettingEditorValue = useMemo(
    () => !noteId || contentLoading || isLoading || isSaving || shouldSkipFetching,
    [contentLoading, isLoading, isSaving, shouldSkipFetching, noteId],
  );
  /** ref to track if we unlocked note for restoring version content */
  const wasLockedForRestoreRef = useRef(false);
  /** ref to track if there are unsaved changes */
  const hasUnsavedChangesRef = useRef(false);
  /** ref to track if initial content load */
  const initialContentLoadRef = useRef(false);

  const clearDebounce = useCallback(() => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
      saveTimeoutRef.current = null;
    }
  }, [saveTimeoutRef]);

  /** resets editor value and content state */
  const onResetEditorValue = useCallback(
    (newValue: EditorValue | null) => {
      if (newValue) {
        updateContent(newValue);
        editorValueRef.current = newValue;
      } else if (isNull(newValue)) {
        updateContent(null);
        editorValueRef.current = null;
      }
      setShouldResetSelection(true);
    },
    [editorValueRef, updateContent],
  );

  /** asset updates */
  const createAsset = useCallback(
    async (assetData: AssetInput) => {
      if (!mStoryId) return;

      const asset = getAssetData(mStoryId, assetData);
      try {
        const result = await createAssetMutation(mStoryId, asset, true);

        return result;
      } catch (error) {
        errorToast(error, 'There was an error trying to create asset');
        log(error);
        return null;
      }
    },
    [createAssetMutation, errorToast, log, mStoryId],
  );

  const onAssetInsert = useCallback(
    async (file: File) => {
      if (!mStoryId) return;

      const assetData = getFileAssetData(mStoryId, file);
      const sourceData = {
        mId: assetData.mId,
        mRefId: assetData.mRefId,
        src: '',
      };

      try {
        const result = await createAssetMutation(mStoryId, assetData, true);
        const { createAssets: assets } = result.data as { createAssets: Asset[] };
        if (assets?.[0]) {
          sourceData.src = assets[0].mContentKey;
        }
      } catch (e) {
        errorToast(e, 'There was an error trying to insert asset.');
        log(e);
      }

      return sourceData;
    },
    [createAssetMutation, errorToast, log, mStoryId],
  );

  /** editor interaction */
  const onForceUnlock = useCallback(async () => {
    if (!note?.mId) return;
    await unlockNote({
      content: null,
      cancelled: false,
      source: 'useNoteEditor:onForceUnlock',
      forceUnlock: true,
    });

    hasUnsavedChangesRef.current = false;
  }, [note?.mId, unlockNote]);

  const onFocusEditor = useCallback(async () => {
    if (
      canUpdate &&
      !isLocked &&
      !isLoading &&
      !isSaving &&
      noteId &&
      !contentLoading &&
      initialContentLoadRef.current
    ) {
      try {
        setDisableMouseEvents(true);
        await Promise.all([refetch(), lockNote(currentUserId)]);
      } catch (err) {
        log(err);
      } finally {
        setDisableMouseEvents(false);
      }
    }
  }, [
    canUpdate,
    isLocked,
    isLoading,
    isSaving,
    noteId,
    contentLoading,
    setDisableMouseEvents,
    refetch,
    lockNote,
    currentUserId,
    log,
  ]);

  const onCancel = useCallback(async () => {
    clearDebounce();

    await unlockNote({
      content: editorValueRef.current ?? defaultContentValue,
      cancelled: true,
      source: 'useNoteEditor:onCancel',
    });
    await refetch();

    hasUnsavedChangesRef.current = false;
  }, [clearDebounce, editorValueRef, refetch, unlockNote]);

  const onSave = useCallback(async () => {
    clearDebounce();

    await unlockNote({
      content: editorValueRef.current ?? defaultContentValue,
      cancelled: false,
      source: 'useNoteEditor:onSave',
    });

    hasUnsavedChangesRef.current = false;
  }, [clearDebounce, editorValueRef, unlockNote]);

  const onContentUpdate = (newContent: EditorValue | null) => {
    editorValueRef.current = newContent;
    hasUnsavedChangesRef.current = true;

    clearDebounce();

    /** trigger debounce */
    saveTimeoutRef.current = setTimeout(() => {
      if (hasUnsavedChangesRef.current) {
        saveContent({ content: newContent, silent: true }).catch(() => {});

        if (editorValueRef.current === newContent) hasUnsavedChangesRef.current = false;
      }
    }, DEBOUNCE_DELAY);
  };

  /** handles editor updates */
  const onEditorUpdate = (input: UpdateInput) => {
    const isLockedByCurrentUser =
      isLocked &&
      getUserIdFromLockedId(lockedBy) === currentUserId &&
      getScopeFromLockedId(lockedBy) === currentEditingScope;

    if (!isLockedByCurrentUser) return;

    const { type, payload } = input;

    switch (type) {
      case ActionTypesEnum.CHANGE:
        onContentUpdate(payload);
        break;

      case ActionTypesEnum.COMMIT_UPDATE:
        saveContent({
          content: editorValueRef.current ?? defaultContentValue,
          silent: true,
          createVersion: true,
        }).catch(() => {});
        hasUnsavedChangesRef.current = false;
        break;

      case ActionTypesEnum.CREATE_ASSET: {
        const { asset } = payload;
        return createAsset(asset);
      }

      case ActionTypesEnum.ASSET_INSERT: {
        const { file } = payload;
        return onAssetInsert(file);
      }

      default:
        return null;
    }
  };

  /** version restoring */
  const onCheckVersionRestorability = useCallback(async () => {
    if (!canUpdate) return false;
    if (lockedBy === currentUserId) return true;

    const lockedId = await lockNote(currentUserId);
    const isLockedByCurrentUser = lockedId && getUserIdFromLockedId(lockedId) === currentUserId;

    if (!isLockedByCurrentUser) {
      errorToast(new Error('Cannot restore version. Note is locked by another user.'));
      return false;
    }

    wasLockedForRestoreRef.current = true;
    return true;
  }, [canUpdate, currentUserId, errorToast, lockNote, lockedBy]);

  restoreVersionFnRef.current = useCallback(
    async (newContent: EditorValue) => {
      clearDebounce();

      const restorable = await onCheckVersionRestorability();
      if (!restorable) return;

      if (wasLockedForRestoreRef.current) {
        await unlockNote({
          content: newContent,
          cancelled: false,
          source: 'useNoteEditor:onRestoreVersion',
        });
        wasLockedForRestoreRef.current = false;
      } else {
        await saveContent({ content: newContent, createVersion: true });
      }
      onResetEditorValue(newContent);
    },
    [clearDebounce, onCheckVersionRestorability, onResetEditorValue, saveContent, unlockNote],
  );

  /** sync states */
  useEffect(() => {
    /** refetch content when mContentKey changes */
    if (
      mContentKey &&
      /** once initial content has been loaded */
      initialContentLoadRef.current &&
      ((!lockedByCurrentUser && !lockedInSameScope) || !isLocked)
    ) {
      refetch().catch(() => {});
    }
  }, [
    currentEditingScope,
    currentUserId,
    isLocked,
    lockedBy,
    lockedByCurrentUser,
    lockedInSameScope,
    mContentKey,
    refetch,
  ]);

  useEffect(() => {
    if (noteId && !initialContentLoadRef.current && s3Data !== undefined && !contentLoading) {
      initialContentLoadRef.current = true;
    }
  }, [content, contentLoading, s3Data, noteId]);

  useEffect(() => {
    if (disableResettingEditorValue) return;
    if ((content !== null && s3Data === null) || s3Data === undefined) return;
    /** updates content state if there's a change in s3 content */
    onResetEditorValue(s3Data);
  }, [content, disableResettingEditorValue, onResetEditorValue, s3Data]);

  useEffect(() => {
    /** resets editor selection when lock state changes */
    setShouldResetSelection(true);
  }, [note?.locked]);

  useEffect(() => {
    /** saves every 5 minutes with latest content */
    periodicSaveRef.current = setInterval(() => {
      if (hasUnsavedChangesRef.current) {
        clearDebounce();

        saveContent({ content: editorValueRef.current, silent: true }).catch(() => {});

        hasUnsavedChangesRef.current = false;
      }
    }, PERIODIC_SAVE_INTERVAL);

    return () => {
      if (periodicSaveRef.current) {
        clearInterval(periodicSaveRef.current);
      }
    };
  }, [clearDebounce, editorValueRef, periodicSaveRef, saveContent]);

  useEffect(
    /** clears local debounce if note is changed or view unmounted */
    () => () => {
      clearDebounce();
      editorValueRef.current = null;
      initialContentLoadRef.current = false;
    },
    [clearDebounce, editorValueRef, noteId],
  );

  return {
    onFocusEditor,
    onForceUnlock,
    onEditorUpdate,
    onRestoreVersion: restoreVersionFnRef.current,
    onCancel,
    onSave,
    refetch,
    contentLoading,
    shouldResetSelection,
  };
};

export default useNoteEditor;
