import { RefObject, useCallback, useMemo, useRef } from 'react';

import { isMdfCategory, mdfCategories, MdfCategory } from 'api/mdf/useGetMdfs';
import { ReactComponent as ListIcon } from 'assets/icons/systemicons/list.svg';
import { ReactComponent as TreeIcon } from 'assets/icons/systemicons/treeview.svg';
import {
  GetTextFromUserProps,
  useGetTextFromUserAsync,
} from 'components/editStringDialog/EditStringDialog';
import useToast from 'components/toast/useToast';
import { TreeView } from 'components/tree';
import { useGetMdfIconFromCategoryAndId } from 'features/mdf/useGetMdfIcon';
import { useAsyncConfirmation } from 'hooks/useAsyncConfirmation';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { ChoiceOptionList, Mdf, TreeChoiceOptionList } from 'types/graphqlTypes';
import { focusSelectedListItem } from 'utils/dom/ulView';

import { OptionListChangeInfoMap } from '../../atomsTs';

import NavBarLeafItem from './NavBarLeafItem';
import NavBarSectionItem from './NavBarSectionItem';
import { createOptionListLabelValidator } from './optionListsHelpers';
import { createSchemaLabelValidator } from './schemaHelpers';
import { SchemaTreeItem, SchemaTreeSection, SectionId } from './types';
import { useSection } from './useSection';

const sectionIds = [...mdfCategories, 'lists', 'trees'] as const;
Object.freeze(sectionIds);

export type Selection = `${SectionId}:${string}`;

function getSelectionParts(selection: Selection): [SectionId, string] {
  const splitPos = selection.indexOf(':');
  return [selection.substring(0, splitPos) as SectionId, selection.substring(splitPos + 1)];
}

export function getMdfIdFromSelection(selection: Selection): string | null {
  const parts = getSelectionParts(selection);
  return isMdfCategory(parts[0]) ? parts[1] : null;
}

export function getOptionListIdFromSelection(selection: Selection): string | null {
  const parts = getSelectionParts(selection);
  return !isMdfCategory(parts[0]) ? parts[1] : null;
}

export function buildSelection(category: SectionId, id: string): Selection {
  return `${category}:${id}`;
}

function getAddItemTooltip(sectionId: SectionId) {
  if (isMdfCategory(sectionId)) return 'Add schema';
  return sectionId === 'lists' ? 'Add option list' : 'Add option tree';
}

function getOptionCollectionIcon(category: 'lists' | 'trees') {
  return category === 'trees' ? TreeIcon : ListIcon;
}

function getMainCategoryDisplayText(category: SectionId) {
  if (isMdfCategory(category)) {
    return 'form';
  } else if (category === 'lists') {
    return 'option list';
  } else {
    return 'option tree';
  }
}

const defaultSelection = buildSelection('defaults', 'story-mdf');

/**
 * Gets the ID of the item to be selected after deleting an item.
 * Will be the item before the item to be deleted unless the item is the first item in the section
 * when it will be the item after. If the last item in the section is deleted, the default
 * selection (Story-Mdf) will be returned.
 * @param section  The section containing the item to be deleted
 * @param deleteId The ID of the item to be deleted
 * @returns        The {@link Selection} to be used if the item is deleted.
 */
function getPostDeleteSelection(section: SchemaTreeSection | undefined, deleteId: string) {
  if (!section || section.children.length < 2) return defaultSelection;
  const deleteIndex = section.children.findIndex((item) => item.id === deleteId);
  const selId = section.children[deleteIndex <= 0 ? 1 : deleteIndex - 1].id;
  return buildSelection(section.id, selId);
}

export interface Props {
  treeRef: RefObject<HTMLUListElement>;
  selection: Selection;
  updateSelection: (selection: Selection) => void;
  /** Holds the categorized MDFs in the system as received from the back-end */
  mdfsSeparated: Readonly<Record<MdfCategory, readonly Mdf[]>>;
  /** Holds the option lists in the system as received from the back-end */
  optionLists: readonly ChoiceOptionList[];
  /** Holds the option trees in the system as received from the back-end */
  optionTrees: readonly TreeChoiceOptionList[];
  /** Holds information about changed MDFs (maps from MDF ID to updated MDF) */
  changedMdfs: Readonly<Record<string, Mdf>>;
  /** Holds information about changed option lists and trees (maps from ID to change info) */
  changedListsAndTrees: OptionListChangeInfoMap;
  /**
   * A function to rename an MDF or a option list/tree
   * @param type  The type of item to be renamed, `'mdf'` to rename an MDF schema, `'options'` to
   *              rename an option list/tree.
   * @param id    The ID of the item to be renamed
   * @param label Tne new label for the item
   */
  renameItem: (type: 'mdf' | 'options', id: string, label: string) => void;
  /**
   * Request deletion of a schema or option list/trees.
   * (Validation and user confirmation has already been done, so deletion should be effectuated
   * immediately)
   * @param type  The type of item to be deleted, `'mdf'` to delete an MDF schema, `'options'` to
   *              delete an option list/tree.
   * @param id    The ID of the item to be renamed
   */
  doDeleteItem: (type: 'mdf' | 'options', id: string) => Promise<void>;
  /**
   * The user has requested to add an item of a given category
   * @param category The type of item to be added
   */
  onAddItem(category: Exclude<SectionId, 'defaults'>): void;
}

function MdfSchemasNavBar({
  treeRef,
  selection,
  updateSelection,
  mdfsSeparated,
  optionLists,
  optionTrees,
  changedMdfs,
  changedListsAndTrees,
  renameItem,
  doDeleteItem,
  onAddItem,
}: Readonly<Props>) {
  const { errorToast } = useToast();
  const getTextFromUserAsync = useGetTextFromUserAsync();
  const [checkUserRight] = useCheckUserRight();
  const canSeeNewCmsWorkflow = checkUserRight('feature', 'cms-blocks');
  const getMdfIcon = useGetMdfIconFromCategoryAndId();
  const confirmAsync = useAsyncConfirmation();

  const selectionPath = useMemo(() => getSelectionParts(selection), [selection]);

  const getChangedMdfLabel = useCallback((id: string) => changedMdfs[id]?.label, [changedMdfs]);
  const defaults = useSection('defaults', mdfsSeparated.defaults, getChangedMdfLabel);
  const instances = useSection('instances', mdfsSeparated.instances, getChangedMdfLabel);
  const blocks = useSection('blocks', mdfsSeparated.blocks, getChangedMdfLabel);
  const custom = useSection('custom', mdfsSeparated.custom, getChangedMdfLabel);
  const subtypes = useSection('subTypes', mdfsSeparated.subTypes, getChangedMdfLabel);
  const getChangedListOrTreeLabel = useCallback(
    (id: string) => changedListsAndTrees[id]?.item.label,
    [changedListsAndTrees],
  );
  const lists = useSection('lists', optionLists, getChangedListOrTreeLabel);
  const trees = useSection('trees', optionTrees, getChangedListOrTreeLabel);
  const sections: readonly SchemaTreeSection[] = useMemo(
    () => [
      defaults,
      instances,
      ...(canSeeNewCmsWorkflow ? [blocks] : []),
      custom,
      subtypes,
      lists,
      trees,
    ],
    [defaults, instances, blocks, custom, subtypes, lists, trees, canSeeNewCmsWorkflow],
  );
  const sectionsRef = useRef(sections);
  sectionsRef.current = sections;

  const updateSelectionPath = useCallback(
    (path: readonly string[]) => {
      if (path.length !== 2 || sectionIds.indexOf(path[0] as SectionId) < 0) {
        updateSelection(defaultSelection);
      } else {
        updateSelection(buildSelection(path[0] as SectionId, path[1]));
      }
    },
    [updateSelection],
  );

  const requestRenameItemImpl = useCallback(
    async (category: SectionId, id: string) => {
      updateSelection(buildSelection(category, id));
      const inputLabel = 'Label';
      const headerText = `Edit ${getMainCategoryDisplayText(category)} label`;

      function getEditOptionsLabelProps(): GetTextFromUserProps {
        const optionListsAndTrees = [...optionLists, ...optionTrees];
        const startValue =
          changedListsAndTrees[id]?.item.label ??
          optionListsAndTrees.find((item) => item.id === id)?.label ??
          '';
        const validator = createOptionListLabelValidator(
          optionListsAndTrees,
          changedListsAndTrees,
          category === 'lists' ? 'list' : 'tree',
          startValue,
        );
        return { headerText, startValue, validator, inputLabel };
      }

      function getEditMdfLabelProps(mdfCategory: MdfCategory): GetTextFromUserProps {
        const startValue =
          changedMdfs[id]?.label ??
          mdfsSeparated[mdfCategory].find((mdf) => mdf.id === id)?.label ??
          '';
        const validator = createSchemaLabelValidator(mdfsSeparated, changedMdfs, startValue);
        return { headerText, startValue, validator, inputLabel };
      }

      const isMdf = isMdfCategory(category);
      const renameProps = isMdf ? getEditMdfLabelProps(category) : getEditOptionsLabelProps();
      const newLabel = await getTextFromUserAsync(renameProps);
      if (newLabel !== false && newLabel !== renameProps.startValue) {
        renameItem(isMdf ? 'mdf' : 'options', id, newLabel);
      }
    },
    [
      getTextFromUserAsync,
      renameItem,
      changedMdfs,
      mdfsSeparated,
      changedListsAndTrees,
      optionLists,
      optionTrees,
    ],
  );
  const requestRenameItemRef = useRef(requestRenameItemImpl);
  requestRenameItemRef.current = requestRenameItemImpl;
  const requestRenameItem = useCallback(
    (category: SectionId, id: string) => {
      void requestRenameItemRef.current(category, id);
    },
    [requestRenameItemRef],
  );

  function getMdfLabel(id: string): string {
    return (
      changedMdfs[id]?.label ??
      Object.values(mdfsSeparated)
        .flat(1)
        .find((mdf) => mdf.id === id)?.label ??
      '<unknown schema>' // should not happen
    );
  }
  const getMdfLabelRef = useRef(getMdfLabel);
  getMdfLabelRef.current = getMdfLabel;

  function getOptionsLabel(id: string): string {
    return (
      changedListsAndTrees[id]?.item.label ??
      optionLists.find((list) => list.id === id)?.label ??
      optionTrees.find((tree) => tree.id === id)?.label ??
      '<unknown options>' // should not happen
    );
  }
  const getOptionsLabelRef = useRef(getOptionsLabel);
  getOptionsLabelRef.current = getOptionsLabel;

  const addItem = useCallback((category: SectionId) => {
    if (category !== 'defaults') onAddItem(category);
  }, []);

  const deleteItem = useCallback((category: SectionId, id: string) => {
    updateSelection(buildSelection(category, id));
    const sectionItem = sectionsRef.current.find((section) => section.id === category);
    const toBeSelectedIfDeleted = getPostDeleteSelection(sectionItem, id);
    const isMdf = isMdfCategory(category);
    const label = isMdf ? getMdfLabelRef.current(id) : getOptionsLabelRef.current(id);
    confirmAsync(
      {
        title: `Delete ${getMainCategoryDisplayText(category)}?`,
        message: `Are you sure you want to delete ${label}?
        This may have unintended side effects places this is in use, and cannot be undone.`,
        usage: 'danger',
        confirmLabel: 'Delete',
      },
      () => doDeleteItem(isMdf ? 'mdf' : 'options', id),
    )
      .then((ok) => {
        if (ok) {
          updateSelection(toBeSelectedIfDeleted);
          focusSelectedListItem(treeRef.current, true);
          // Delete succeeded
        }
      })
      .catch(errorToast);
  }, []);

  const itemRenderer = useCallback(
    ({ category, id, label, changed }: SchemaTreeItem) => {
      if (category === 'section') {
        return NavBarSectionItem({
          id,
          label,
          addTooltip: getAddItemTooltip(id),
          addItem: id !== 'defaults' ? addItem : undefined,
        });
      }
      const Icon = isMdfCategory(category)
        ? getMdfIcon(category, id)
        : getOptionCollectionIcon(category);
      return NavBarLeafItem({
        category,
        id,
        label,
        changed,
        Icon,
        deleteItem,
        renameItem: requestRenameItem,
      });
    },
    [getMdfIcon],
  );

  const onRenameNode = useCallback(
    (path: readonly string[]) =>
      path.length === 2 && requestRenameItem(path[0] as SectionId, path[1]),
    [requestRenameItem],
  );

  const onDeleteNode = useCallback(
    (path: readonly string[]) => path.length === 2 && deleteItem(path[0] as SectionId, path[1]),
    [deleteItem],
  );

  const onAddChildNode = useCallback(
    (path: readonly string[]) => path.length === 1 && addItem(path[0] as SectionId),
    [requestRenameItem],
  );

  return TreeView({
    treeRef,
    items: sections,
    selectableFolders: false,
    selectionPath,
    updateSelectionPath,
    fillWidth: true,
    accordion: true,
    contentRenderer: itemRenderer,
    onRenameNode,
    onDeleteNode,
    onAddChildNode,
  });
}

export default MdfSchemasNavBar;
