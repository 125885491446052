import { CognitoUser } from '@aws-amplify/auth';
import { atom, useAtom } from 'jotai';

// Temporary user to be used to pass user/username between forms
const tempUser = atom<CognitoUser | string | null>(null);
export const useTempUser = () => useAtom(tempUser);

// Loading state for all cognito requests
const loading = atom(false);
export const useLoading = () => useAtom(loading);

export type FormId =
  | 'DEFAULT'
  | 'FORCE_RESET_PASSWORD'
  | 'DEFAULT_LOGIN'
  | 'FORGOT_PASSWORD'
  | 'RESET_PASSWORD';

// Current form to be shown in the middle
const currentForm = atom<FormId>('DEFAULT');
export const useCurrentForm = () => useAtom(currentForm);

// Did password reset
const isReset = atom(false);
export const useIsReset = () => useAtom(isReset);
