import React, { KeyboardEventHandler, memo, useCallback } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { Editor } from 'slate';
import { RenderElementProps, RenderLeafProps, RenderPlaceholderProps } from 'slate-react';

import useCheckUserRight from 'hooks/useCheckUserRight';
import { BlockElement, EditorFontSize } from 'types/editor';
import { PlatformStructure } from 'types/graphqlTypes';
import preventDefaultEvent from 'utils/preventDefaultEvent';
import stopEventPropagation from 'utils/stopEventPropagation';

import onCheckListKeyDown from './components/checkList/onCheckListKeyDown';
import CustomPlaceholder from './components/customPlaceholder';
import onHorizontalRuleKeyDown from './components/horizontalRule/utils/onHorizontalRuleKeyDown';
import Leaf from './components/leaf';
import onLeafKeyDown from './components/leaf/utils/onLeafKeydown';
import { onLinkKeyDown } from './components/link/utils';
import onListKeyDown from './components/list/utils/onListKeyDown';
import onMentionKeyDown from './components/mention/utils/onMentionKeyDown';
import onParagraphKeyDown from './components/paragraph/utils/onParagraphKeyDown';
import onPrimaryKeyDown from './components/primaryAutomation/utils/onPrimaryKeyDown';
import onQuoteKeyDown from './components/quote/utils/onQuoteKeyDown';
import { BlockMap, SupportedBlockTypes } from './componentsV2/elementComponents';
import elementComponents from './constants/elementComponents';
import variants from './constants/types/editorVariants';
import onEditorKeyDown from './utils/onEditorKeyDown';
import { useEditorContext } from './EditorContext';
import { useEditorMolecule } from './store';
import { EditorVariant } from './types';
import {
  onAssetElementKeyDown,
  onElementKeyDown,
  onPaste,
  onRestrictedElementKeyDown,
  onVoidKeyDown,
  onWrapSection,
} from './utils';

import { Editable, EditableWrapper } from './styled';

interface CustomEditableProps {
  variant: EditorVariant;
  height: string | number;
  padding: number;
  editorFontSize: EditorFontSize;
  onFocus: React.FocusEventHandler<HTMLDivElement>;
  onBlur: React.FocusEventHandler<HTMLDivElement>;
  placeholder?: string;
  isAllowed: boolean;
  isCmsBlock: boolean;
  readOnly?: boolean;
  editor: Editor;
  direction: string;
  platformStructure?: PlatformStructure;
}

function CustomEditable({
  padding,
  editorFontSize,
  variant,
  onFocus,
  onBlur,
  placeholder,
  readOnly,
  direction,
  editor,
  isAllowed,
  isCmsBlock,
  platformStructure,
}: Readonly<CustomEditableProps>) {
  const { onDone, update, onSave, onHotKeys } = useEditorContext();
  const { useShowDeleteAllDialog, useCommandTarget, useShowHoveringToolbar } = useEditorMolecule();
  const [checkUserRight] = useCheckUserRight();

  const [, setShowDeleteAllDialog] = useShowDeleteAllDialog();
  const [, setCommandTarget] = useCommandTarget();
  const [, setShowHoveringToolbar] = useShowHoveringToolbar();
  const canUseSectionDivider = checkUserRight('feature', 'section-divider');
  const canSeeNewCmsWorkflow = isCmsBlock && checkUserRight('feature', 'cms-blocks');
  const canUseNativeDrag = checkUserRight('feature', 'improved-dnd');

  const renderElement = useCallback(
    (elementProps: RenderElementProps) => {
      const type = elementProps.element.type;
      if (canSeeNewCmsWorkflow) {
        const Component: React.FC<BlockElement> | undefined = BlockMap[type as SupportedBlockTypes];
        if (Component) return <Component direction={direction} {...elementProps} />;
      } else if (canUseNativeDrag && type === 'paragraph') {
        const Component: React.FC<BlockElement> | undefined = BlockMap[type as SupportedBlockTypes];
        if (Component) return <Component direction={direction} {...elementProps} />;
      }
      const ElementComponent = elementComponents(elementProps, variant);
      return <ElementComponent readOnly={undefined} direction={direction} {...elementProps} />;
    },
    [variant, direction, canUseNativeDrag, canSeeNewCmsWorkflow, readOnly],
  );

  const renderLeaf = useCallback((leafProps: RenderLeafProps) => <Leaf {...leafProps} />, []);

  const renderPlaceholder = useCallback(
    (placeholderProps: RenderPlaceholderProps) => <CustomPlaceholder {...placeholderProps} />,
    [],
  );

  const handlePaste: React.ClipboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      onPaste(event, editor);
    },
    [editor],
  );

  const handleSectionWrap = useCallback(
    (unwrap = false) => {
      if (canUseSectionDivider && update)
        onWrapSection(editor, update, platformStructure?.config, unwrap);
    },
    [canUseSectionDivider, platformStructure, editor],
  );

  const showDeleteAllDialog = useCallback(() => {
    setShowDeleteAllDialog((prev) => !prev);
  }, []);

  const onEscape = useCallback(() => setShowHoveringToolbar(false), []);

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      onEditorKeyDown({ editor, event, update, onSave, onEscape });
      onLeafKeyDown(editor, event);
      onLinkKeyDown(editor, event);
      onMentionKeyDown(editor, event);
      onRestrictedElementKeyDown(editor, event, showDeleteAllDialog);
      onElementKeyDown(
        editor,
        variant,
        handleSectionWrap,
        event,
        {
          isAllowed,
          isCmsBlock,
          isMessageVariant: variant === variants.MESSAGE,
        },
        onDone,
      );
      onParagraphKeyDown(editor, event, variant, isAllowed, isCmsBlock);
      onQuoteKeyDown(editor, event);
      onCheckListKeyDown(editor, event);
      onListKeyDown(editor, event);
      onHorizontalRuleKeyDown(editor, event);
      onAssetElementKeyDown(editor, event, update);
      onPrimaryKeyDown(editor, event, update);
      onVoidKeyDown(editor, event, variant, isAllowed, isCmsBlock);
      if (onHotKeys) void onHotKeys(event);
    },
    [onDone, handleSectionWrap, update, onEscape],
  );

  /** stop event bubble for onkeyup: for rundown grid */
  const onKeyUp: React.KeyboardEventHandler<HTMLDivElement> = useCallback((event) => {
    preventDefaultEvent(event);
    stopEventPropagation(event);
  }, []);

  const handleClickAway = useCallback(() => setCommandTarget(null), []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <EditableWrapper
        padding={padding}
        $fontSize={canSeeNewCmsWorkflow ? 'none' : editorFontSize}
        messageVariant={variant === variants.MESSAGE}
        onMouseDown={preventDefaultEvent}
      >
        <Editable
          autoFocus
          onMouseDown={stopEventPropagation}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          readOnly={readOnly}
          renderPlaceholder={renderPlaceholder}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onDrop={() => true}
          onPaste={handlePaste}
        />
      </EditableWrapper>
    </ClickAwayListener>
  );
}

export default memo(CustomEditable);
