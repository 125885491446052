import SplitPane from 'react-split-pane';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ContentWrapper = styled('div')<{ $disableMouseEvents: boolean }>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  pointer-events: ${({ $disableMouseEvents }) => ($disableMouseEvents ? 'none' : 'auto')};
  cursor: ${({ $disableMouseEvents }) => ($disableMouseEvents ? 'not-allowed' : 'auto')};
`;

export const Body = styled('div')`
  display: flex;
  flex: 1;
  height: 100%;
  overflow: auto;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundMain};
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`;

export const ThumbWrapper = styled('div')`
  z-index: 1101;
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const SidebarWrapper = styled('div')`
  z-index: 100;
  display: flex;
  height: 100%;
  width: 100%;
`;

export const CenterContainer = styled('main')`
  flex: 1;
  position: relative;
  height: 100%;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundMain};
`;

export const CenterWrapper = styled('div')`
  position: absolute;
  inset: 0;
`;

export const TickerWrapper = styled('div')`
  z-index: 1000;
  height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  transition: 0.3s;
`;

/**
 * If isDragging is true, this div will catch mouse events such as mouseover. This fixes
 * a poor user experience when resizing a panel with an external website in it
 */
export const MouseEventCatcher = styled('div')`
  pointer-events: ${({ isDragging }: { isDragging: boolean }) => (isDragging ? '' : 'none')};
  z-index: 99999;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const Split = styled(SplitPane)`
  overflow: visible !important;
  .Resizer {
    box-sizing: border-box;
    background-clip: padding-box;
    cursor: col-resize;
    transition: all 150ms ease-in-out;
    background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
    :hover,
    :active {
      ${({ theme }) => css`
        background-color: ${theme.palette.dina.onFocus};
        box-shadow: 0px 0px 0px 1px ${theme.palette.dina.onFocus};
      `}
    }
  }
`;
