import { CellContext } from '@tanstack/react-table';

import MemberLabel from 'components/addMember/MemberLabel';
import { MemberType } from 'types/graphqlTypes';

type DateCellProps = Pick<CellContext<MemberType, string>, 'getValue'>;

const style = { overflow: 'hidden', width: '100%' };

export default function MemberCell({ getValue }: Readonly<DateCellProps>) {
  const createdById = getValue();

  return <MemberLabel variant="grid" memberId={createdById} readOnly style={style} />;
}
