import { v1 as uuidv1 } from 'uuid';

import { EditorValue } from 'types/editor';

import { EditorVariant } from '../types';

import variants from './types/editorVariants';
import elementTypes from './types/elementTypes';
import version from './version';

const initialValue = (initialText: string) => ({
  document: [
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: initialText }],
    },
  ],
  version,
});

const twitterInitialValue = (initialText: string) => ({
  version,
  document: [
    {
      type: 'tweet-thread',
      data: {},
      children: [
        {
          type: 'description',
          data: {
            content: initialText,
          },
          children: [
            {
              text: '',
            },
          ],
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
});

const facebookInitialValue = (initialText: string) => ({
  version,
  document: [
    {
      type: 'description',
      data: {
        content: initialText,
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'tags',
      data: {
        content: [],
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
});

const cmsBlockInitialValue = (initialText: string) => ({
  version,
  document: [
    {
      type: 'title',
      data: {
        content: '',
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'abstract',
      data: {
        content: initialText,
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'section',
      data: {
        content: [],
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'source',
      data: {
        content: [],
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'author',
      data: {
        content: [],
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'tags',
      data: {
        enableAutocomplete: true,
        content: [],
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
});

const youtubeInitialValue = (initialText: string) => ({
  version,
  document: [
    {
      type: 'title',
      data: {
        content: '',
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'description',
      data: {
        content: initialText,
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'video',
      data: {
        showThumbnail: true,
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'tags',
      data: {
        content: [],
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'privacy',
      data: {
        content: 'public',
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
});

const noteInitialValue = (initialText: string) => ({
  version,
  document: [
    {
      type: elementTypes.HEADING_TWO,
      placeholder: true,
      children: [{ text: initialText }],
    },
    {
      type: elementTypes.PARAGRAPH,
      placeholder: true,
      children: [{ text: '' }],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
});

const tiktokInitialValue = (initialText: string) => ({
  version,
  document: [
    {
      type: 'video',
      data: {
        showThumbnail: false,
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: initialText }],
    },
  ],
});

const linkedInInitialValue = (initialText: string) => ({
  document: [
    {
      type: 'description',
      data: {
        content: initialText,
      },
      children: [
        {
          text: '',
        },
      ],
    },
  ],
  version,
});

const newCmsInitialValue = (initialText: string) => ({
  version,
  document: [
    {
      type: 'title',
      data: {
        content: '',
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'description',
      data: {
        content: '',
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: initialText }],
    },
  ],
});

const initialValues = (
  variant: EditorVariant,
  _isAllowed: boolean | undefined, // To be deprecated
  isCmsBlock: boolean | undefined,
  shouldUseNewCmsWorkflow: boolean,
  initialText: string | undefined = '',
): EditorValue => {
  if (shouldUseNewCmsWorkflow && isCmsBlock) {
    return newCmsInitialValue(initialText);
  }
  if (variant === variants.TWITTER) return twitterInitialValue(initialText);
  if (variant === variants.FACEBOOK) return facebookInitialValue(initialText);
  if (variant === variants.INSTAGRAM) return facebookInitialValue(initialText);
  if (variant === variants.CMS && isCmsBlock) return cmsBlockInitialValue(initialText);
  if (variant === variants.CMS) return initialValue(initialText);
  if (variant === variants.YOUTUBE) return youtubeInitialValue(initialText);
  if (variant === variants.NOTES) return noteInitialValue(initialText);
  if (variant === variants.TIKTOK) return tiktokInitialValue(initialText);
  if (variant === variants.LINKEDIN) return linkedInInitialValue(initialText);
  return initialValue(initialText);
};

export default initialValues;
