import { useCallback } from 'react';
import styled from '@emotion/styled';
import { CellContext } from '@tanstack/react-table';

import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/side_preview.svg';
import { Button } from 'components/buttons';
import useOpenMember from 'components/contextMenu/useOpenMember';
import { Icon } from 'components/memberIcon/MemberIcon';
import Text from 'components/text/Text';
import { HStack } from 'layouts/box/Box';
import { useSetPreview } from 'store/preview';
import { ParsedMemberType } from 'types/members';

type TitleCellProps = Pick<CellContext<ParsedMemberType, string>, 'row' | 'getValue'>;

export const StyledHStack = styled(HStack)`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  gap: 4px;
  position: relative;

  button {
    z-index: 1;
    width: 70px;
    height: 24px;
    right: 10px;
    display: none;
    position: absolute;
    :hover {
      background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2} !important;
      border: 1px solid rgba(255, 255, 255, 0.6);
    }
    :active {
      margin-top: 1px;
    }
  }

  :hover button {
    display: flex;
    background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  }
  .title-text {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;

export default function TitleCell({ getValue, row }: Readonly<TitleCellProps>) {
  const title = getValue() ?? '';

  const setPreview = useSetPreview();
  const { openItem } = useOpenMember();

  const openHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      openItem(row.original);
    },
    [openItem, row.original],
  );

  const previewHandler = useCallback(() => {
    setPreview(row.original);
  }, [row.original, setPreview]);

  return (
    <StyledHStack onClick={previewHandler}>
      <Icon member={row.original} />
      <Text variant="listItemLabel" truncate className="title-text">
        {title}
      </Text>
      <Button
        variant="outlined"
        usage="outlined"
        startIcon={<SidebarActive />}
        onClick={openHandler}
        width={70}
      >
        Open
      </Button>
    </StyledHStack>
  );
}
