import { useCallback, useContext, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import keyBy from 'lodash/keyBy';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { BlockWithLabel } from 'api/mdfBlocks/types';
import { getOptionLists } from 'api/optionLists/useGetOptionLists';
import { getInput, search } from 'api/search';
import UserContext from 'contexts/UserContext';
import { CommandToolbarProps } from 'features/command/command-types';
import { batchGetMembersFromFieldValue } from 'features/gridDeck/api/useBatchGetItems';
import { getBlocksWithMdf } from 'features/print/hooks/useGetBlocksWithMdf';
import { getOrdersWithMdf } from 'features/print/hooks/useGetOrdersWithMdf';
import useCopyText from 'hooks/useCopyText';
import useGetMembersInfo from 'hooks/useGetMembersInfo';
import useSettingsValue from 'hooks/useSettingsValue';
import { getOrdersForEntities, rawToOrder } from 'screens/space/api/useGetOrdersAndForms';
import { MiniMember } from 'types/forms/forms';
import {
  GetOrderEnum,
  MemberType,
  Metadata,
  SearchItemTypeEnum,
  TaskStatusEnum,
} from 'types/graphqlTypes';
import { findContactIdsInMetadata } from 'utils/mdf/findContactIdsInMetadata';
import { findRelationMembersInMetadata } from 'utils/mdf/findRelationMembersInMetadata';

import useCopyUtils from './useCopyUtils';

const baseToolbarState: CommandToolbarProps = {
  sortBy: 'createdAt',
  order: 'desc',
  rangeBy: null,
  defaultMdfId: null,
  mdfId: '',
  isFiltering: false,
  statusFilter: [],
  assignedIds: [],
  createdByIds: [],
  mTypes: [],
};

const useCopyPlanning = (blocks: BlockWithLabel[]) => {
  const client = useApolloClient();
  const { getBlockContent } = useCopyUtils();
  const { groups } = useContext(UserContext);
  const { getMemberTitle, getMember } = useGetMembersInfo();
  const { onCopy: copyToClipboard } = useCopyText();
  const [getSettingsValue] = useSettingsValue();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const contactFieldsToPrint =
    getSettingsValue('app.contactFieldsToPrint', 'app.contactFieldsToPrint', 'all')?.toString() ??
    'all';

  const mIds = useMemo(() => blocks.map((block) => block.mRefId), [blocks]);

  const { mdfs, mdfsSeparated, loading: mdfsLoading, error: mdfsError } = useGetMdfs();

  const subTypes = keyBy(mdfsSeparated?.subTypes, (mdf) => mdf.label);

  const contactMdf = useMemo(
    () => mdfsSeparated?.defaults.find((mdf) => mdf.id.toLowerCase().includes('contact')),
    [mdfsSeparated],
  );

  const onCopy = useCallback(async () => {
    if (mdfsLoading) {
      return;
    }

    if (mdfsError) {
      setError(new Error('Error loading MDFs'));
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const { data } = await getOrdersForEntities(client, {
        mIds,
        requestType: GetOrderEnum.Resource,
        mStatus: TaskStatusEnum.all,
      });

      const blocksWithMdf = getBlocksWithMdf(blocks, mdfs, mdfsSeparated);
      const ordersWithMdf = getOrdersWithMdf(
        (data?.getOrdersForEntities ?? []).map(rawToOrder),
        mdfs,
        mdfsSeparated,
      );

      const items = [...blocksWithMdf, ...ordersWithMdf];

      // remove empty metadata fields from copy payload
      items.forEach((item) => {
        const { metadata } = item;
        Object.keys(metadata).forEach((key) => {
          if (metadata[key] === null || metadata[key] === undefined || metadata[key] === '') {
            delete metadata[key];
          }
        });
      });

      const { contacts: contactIds, relationMembers: relationMiniMembers } = items.reduce(
        (acc, item) => {
          const { mdf, metadata } = item;

          acc.contacts.push(...findContactIdsInMetadata(mdf, metadata, groups, subTypes));
          acc.relationMembers.push(
            ...findRelationMembersInMetadata(mdf, metadata, groups, subTypes),
          );

          return acc;
        },
        { contacts: [] as string[], relationMembers: [] as MiniMember[] },
      );

      const {
        data: { batchGetMembers: relationMembersData },
      } = relationMiniMembers.length
        ? await batchGetMembersFromFieldValue(client, relationMiniMembers)
        : { data: { batchGetMembers: [] as MemberType[] } };

      const { items: contactsData } = await search(
        client,
        getInput(
          '',
          undefined,
          { ...baseToolbarState, mTypes: [SearchItemTypeEnum.contact] },
          25,
          {},
          undefined,
          [],
          contactIds,
          undefined,
        ),
      );

      const { contacts: connectedContacts, relationMembers: connectedRelationMembers } = [
        ...contactsData,
        ...relationMembersData,
      ].reduce(
        (acc, member) => {
          if (!member.metadata) return acc;

          let metadata: Metadata;
          try {
            metadata = JSON.parse(member.metadata) as Metadata;
          } catch {
            return acc;
          }

          acc.contacts.push(...findContactIdsInMetadata(contactMdf, metadata, groups, subTypes));
          acc.relationMembers.push(
            ...findRelationMembersInMetadata(contactMdf, metadata, groups, subTypes),
          );

          return acc;
        },
        { contacts: [] as string[], relationMembers: [] as MiniMember[] },
      );

      const {
        data: { batchGetMembers: contactRelationFields },
      } = connectedRelationMembers.length
        ? await batchGetMembersFromFieldValue(client, connectedRelationMembers)
        : { data: { batchGetMembers: [] } };

      const { items: connectedContactsData } = await search(
        client,
        getInput(
          '',
          undefined,
          { ...baseToolbarState, mTypes: [SearchItemTypeEnum.contact] },
          25,
          {},
          undefined,
          [],
          connectedContacts,
          undefined,
        ),
      );

      const allRelationFields = [...relationMembersData, ...contactRelationFields];
      const allContacts = [...contactsData, ...connectedContactsData];

      const { data: optionLists } = await getOptionLists(client);

      const allContent = blocksWithMdf
        .map((block) => {
          const { mTitle, mRefId, mdf, metadata } = block;

          return getBlockContent({
            root: true,
            blockTitle: mTitle,
            metadata,
            orders: ordersWithMdf.filter((order) => order.mResourceId === mRefId),
            groups,
            fields: mdf?.fields,
            getMemberTitle,
            getMember,
            relationMembers: allRelationFields,
            contacts: allContacts,
            contactFieldsToPrint,
            mdfsSeparated,
            subMdfs: mdfsSeparated.subTypes,
            layoutSettings: mdf?.views?.default,
            permissions: mdf?.permissions,
            optionLists,
          });
        })
        .join('<br>');

      await copyToClipboard(allContent, 'Items copied to clipboard');
      setLoading(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      if (err instanceof Error) {
        setError(err);
      }
      setLoading(false);
    }
  }, [
    blocks,
    client,
    contactFieldsToPrint,
    contactMdf,
    copyToClipboard,
    getMember,
    getMemberTitle,
    groups,
    mIds,
    mdfs,
    mdfsError,
    mdfsLoading,
    mdfsSeparated,
  ]);

  return { onCopy, loading: loading || mdfsLoading, error };
};

export default useCopyPlanning;
