import { MdfCategory } from 'api/mdf/useGetMdfs';
import { Mdf } from 'types/graphqlTypes';

/**
 * Creates a function that can be used to validate the label of an option list/tree
 * @param mdfsSeparated The schemas in the system as retrieved from the back-end
 * @param changedMdfs   The schemas that have been changed but not yet saved
 * @param originalLabel The original label
 * @returns             The validator function that receives a label and returns an error
 *                      message text if not OK or `true` if ok.
 */
export function createSchemaLabelValidator(
  mdfsSeparated: Readonly<Record<MdfCategory, readonly Mdf[]>>,
  changedMdfs: Readonly<Record<string, Mdf>>,
  originalLabel: string,
): (label: string) => string | boolean {
  const allMdfs = Object.values(mdfsSeparated).flat();
  const byId = Object.fromEntries(allMdfs.map((l) => [l.id, l]));
  Object.values(changedMdfs).forEach((mdf) => (byId[mdf.id] = mdf));
  const byLabel = Object.fromEntries(Object.values(byId).map((l) => [l.label, l]));

  return (label) => {
    if (!label) {
      return 'Empty schema labels are not allowed';
    }
    if (label === originalLabel) {
      return false;
    }
    const existing = byLabel[label];
    if (!existing) return true;
    return `'${label}' is already used as label for another schema`;
  };
}
