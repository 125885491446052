import { useMemo, useRef } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import { EditorValue, Note } from 'types';
import { ExportContentInput, MemberType } from 'types/graphqlTypes';

export const NotesScope = createScope<string | undefined>(undefined);
export type NotesScopeObject =
  | { view: 'widget'; viewRef: string }
  | {
      view: 'story';
      paneIndex: number;
    };

export const notesMolecule = molecule((_getMol, getScope) => {
  const scopeString = getScope(NotesScope);

  const canUpdateNoteAtom = atom(false);

  const selectedNoteAtom = atom<MemberType | null>(null);

  const toViewHistoryAtom = atom<Note | null>(null);
  const toDeleteNoteAtom = atom<Note | null>(null);
  const toPrintNoteAtom = atom<Note | null>(null);
  const printConfigAtom = atom<ExportContentInput | null>(null);

  const editorValueRef = useRef<EditorValue | null>(null);
  const restoreVersionFnRef = useRef<((content: EditorValue) => Promise<void>) | null>(null);

  const scopeView = atom(() => {
    if (!scopeString) return;
    const scopeObject = JSON.parse(scopeString) as NotesScopeObject;

    return scopeObject.view;
  });

  const scopeViewRef = atom(() => {
    if (!scopeString) return;
    const scopeObject = JSON.parse(scopeString) as NotesScopeObject;

    return scopeObject.view === 'story' ? scopeObject.paneIndex : scopeObject.viewRef;
  });

  const { scope: storyScope } = useStoryPaneMolecule();
  const currentEditingScope = useMemo(() => {
    if (storyScope) return storyScope;
    if (scopeString) {
      const notesScope = JSON.parse(scopeString) as NotesScopeObject;
      if (notesScope.view === 'widget') return notesScope.viewRef;
    }
    return 'note-widget';
  }, [scopeString, storyScope]);

  return {
    // return atoms
    canUpdateNoteAtom,
    selectedNoteAtom,
    toViewHistoryAtom,
    toDeleteNoteAtom,
    toPrintNoteAtom,
    printConfigAtom,
    // return hooks
    useSelectedNote: () => useAtom(selectedNoteAtom),
    useCanUpdateNote: () => useAtom(canUpdateNoteAtom),
    useToViewHistoryNote: () => useAtom(toViewHistoryAtom),
    useToDeleteNote: () => useAtom(toDeleteNoteAtom),
    useToPrintNote: () => useAtom(toPrintNoteAtom),
    usePrintConfig: () => useAtom(printConfigAtom),
    useScopeViewValue: () => useAtomValue(scopeView),
    useScopeViewRefValue: () => useAtomValue(scopeViewRef),
    // return refs
    editorValueRef,
    restoreVersionFnRef,
    currentEditingScope,
  };
});

export const useNotesMolecule = () => useMolecule(notesMolecule);
