import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import Popper from 'components/shared/popper';
import Calendar from 'components/calendar';
import useCustomDateTimeUtils from 'hooks/useCustomDateTimeUtils';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { timeVariants } from 'utils/planningViews';
import {
  CalendarWrapper,
  DisplayView,
  PickerWrapper,
  ScheduleView,
} from './timeIndicatorPicker-styled';

const TimeIndictorPicker = ({
  timeVariant,
  time,
  onChange,
  isScheduleView,
  showScheduled,
  disabled,
}) => {
  const { format } = useDateTimeUtils();
  const { getRelativeDate } = useCustomDateTimeUtils();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (e) => {
    if (disabled) return;
    setAnchorEl(e.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (dateRange) => {
    const { startDate = new Date().toISOString() } = dateRange;
    onChange(startDate, timeVariant);
    handlePopoverClose();
  };

  const unschedueledFormattedDate = useMemo(() => {
    if (showScheduled === false) return 'Showing unscheduled only';
    if (showScheduled === null) return 'Showing both scheduled and unscheduled';
    if (timeVariant === timeVariants.DAY) return format(time, 'E. d MMM. yyyy');
    if (timeVariant === timeVariants.WEEK) return format(time, "MMM. yyyy, 'Week' w");
    if (timeVariant === timeVariants.MONTH) return format(time, 'MMMM yyyy');
    if (timeVariant === timeVariants.YEAR) return format(time, 'yyyy');
    return null;
  }, [showScheduled, time, timeVariant]);

  return (
    <PickerWrapper>
      {isScheduleView ? (
        <ScheduleView onClick={handlePopoverOpen} disabled={disabled} data-testid="display">
          {getRelativeDate(time)}
        </ScheduleView>
      ) : (
        <DisplayView onClick={handlePopoverOpen} disabled={disabled} data-testid="display">
          {unschedueledFormattedDate}
        </DisplayView>
      )}
      <Popper anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handlePopoverClose}>
          <CalendarWrapper data-testid="popover">
            <Calendar
              selectedDate={time}
              changeSelectedDate={handleOnChange}
              changeSelectedDateRange={handleOnChange}
              hideUnscheduleButton
              onClose={handlePopoverClose}
              disabled={disabled}
            />
          </CalendarWrapper>
        </ClickAwayListener>
      </Popper>
    </PickerWrapper>
  );
};

TimeIndictorPicker.propTypes = {
  /** Variant of time navigator,
   *  defines time resolution
   */
  timeVariant: PropTypes.oneOf(['date', 'week', 'month', 'year']).isRequired,
  /** Initial time assigned to the components */
  time: PropTypes.string,
  /** Callback to be invoked when navigator's button clicked */
  onChange: PropTypes.func,
  /** To determine if the timeIndicatorPicker is being called from Schedule component or not */
  isScheduleView: PropTypes.bool,
  /** boolean to show show unscheduled text */
  showScheduled: PropTypes.bool,
  /** disable updating value */
  disabled: PropTypes.bool,
};

TimeIndictorPicker.defaultProps = {
  time: new Date().toISOString(),
  onChange: () => {},
  isScheduleView: false,
  showScheduled: false,
  disabled: false,
};

export default TimeIndictorPicker;
