import { CustomElement, CustomText, EditorValue, isCustomElement } from 'types';

const getValue = (children: readonly (CustomElement | CustomText)[]): string =>
  children
    .reduce((acc, child) => {
      if (isCustomElement(child)) {
        acc += getValue(child.children);
      } else {
        acc += child.text || '';
      }
      return acc;
    }, '')
    .replace(/\s\s+/g, ' ');

const destructureEditorValue = (editorValue: EditorValue | null) => {
  let mTitle = '';
  let mDescription = '';

  try {
    if (editorValue?.document[0]?.children) {
      mTitle = getValue(editorValue.document[0].children) || '';
    }

    if (editorValue?.document[1]?.children) {
      mDescription = getValue(editorValue.document[1].children) || '';
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error destructuring editor value:', error);
    // Fallback to empty strings if there's an error
    mTitle = '';
    mDescription = '';
  }

  return { mTitle, mDescription };
};

export default destructureEditorValue;
