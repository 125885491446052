/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import styled from '@emotion/styled';
import { keyBy } from 'lodash';
import PropTypes from 'prop-types';

import { useAuthContext } from 'contexts/AuthContext';
import BreakingFeedCtx from 'contexts/BreakingFeedContext';
import NotificationCtx from 'contexts/NotificationContext';
import UserCtx, { UserContextProps } from 'contexts/UserContext';
import { getSignInUser } from 'features/auth';
import initialQueries from 'operations/global-functions/initialQueries';
import GET_USER from 'operations/queries/getUser';
import { getMemberQuery } from 'operations/queryVariables';
import InitialLoadingScreen from 'screens/loading';
import { useUserData } from 'screens/main/hooks';
import { useUserBookmarks } from 'store/bookmarks';
import { User, UserBookmark } from 'types';
import { GetMemberResult } from 'types/graphqlTypes';

const ErrorWrapper = styled('div')`
  color: white;
  position: fixed;
  top: 30%;
  left: 25%;
  font-size: 25px;
`;

interface Props {
  children: React.ReactElement;
}

function ProtectedProvider({ children }: Readonly<Props>) {
  const context = useAuthContext();
  const [initialLoading, setInitialLoading] = useState(true);
  const signedInUser = getSignInUser(context) as UserContextProps;
  const [, setBookmarks] = useUserBookmarks();

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  initialQueries(signedInUser.mId, signedInUser.groups, setInitialLoading);
  const [userDataError] = useUserData(signedInUser.mId);

  const { data, error, loading } = useQuery<GetMemberResult<User>>(GET_USER, {
    variables: getMemberQuery(signedInUser.mId),
    onCompleted: (d) => {
      const bookmarks = (d.getMember.mBookmarks ?? []).map(
        ({ bookmarkedType, bookmarkedId }) =>
          ({
            bookmarkedType,
            bookmarkedId,
          } as UserBookmark),
      );
      const keyedBookmarks = keyBy(bookmarks, (bookmark) => bookmark.bookmarkedId);
      setBookmarks(keyedBookmarks);
    },
  });

  if (error) {
    return <div> {String(error)} </div>;
  }

  if (userDataError) {
    // eslint-disable-next-line no-console
    console.warn('Failed loading user data', userDataError);
  }

  if (!initialLoading && !data?.getMember) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Auth.signOut();
    return (
      <ErrorWrapper>
        Something went wrong! Please reload your browser and sign in again.
      </ErrorWrapper>
    );
  }

  if (initialLoading || loading) {
    return <InitialLoadingScreen />;
  }

  signedInUser.attributes = data?.getMember;

  return (
    <UserCtx.Provider value={signedInUser}>
      <NotificationCtx>
        <BreakingFeedCtx>{children}</BreakingFeedCtx>
      </NotificationCtx>
    </UserCtx.Provider>
  );
}

ProtectedProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedProvider;
