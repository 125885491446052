import React, { useMemo } from 'react';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { FieldValue, getAllFieldsAndSettings, Metadata } from 'types/forms/forms';
import { FieldTypeEnum, LayoutSettings, Mdf, MdfField, ViewTypes } from 'types/graphqlTypes';

export type ErrorMap = Record<string, string | undefined>;

const generateTooltipText = (
  map: Record<string, string | undefined>,
  labelMap: Record<
    string,
    MdfField & {
      settings: LayoutSettings;
    }
  >,
): string[] => {
  const tooltips: string[] = [];
  for (const [fieldId, error] of Object.entries(map)) {
    const label = labelMap[fieldId]?.settings?.label ?? fieldId;
    if (error) {
      tooltips.push(`${label}: ${error}`);
    }
  }
  return tooltips;
};

const hasNoValue = (val: FieldValue): boolean => {
  if (val === undefined || val === null) return true;
  if (typeof val === 'string' && val.length === 0) return true;
  if (Array.isArray(val) && val.length === 0) return true;
  return false;
};

export const getErrorMap = (fieldMap: Record<string, MdfField>, metadata: Metadata): ErrorMap => {
  const map: ErrorMap = {};
  for (const model of Object.values(fieldMap)) {
    if (model.required && hasNoValue(metadata[model.fieldId])) {
      map[model.fieldId] = 'Required value';
    }
  }
  return map;
};

/**
 * Takes care of handling errors for a given metadata payload.
 */
const useMdfErrorMap = (
  mainMdf: Mdf | undefined,
  metadata: Metadata | null,
  specificView: ViewTypes = 'default',
) => {
  const { mdfs, mdfsSeparated } = useGetMdfs();

  const fieldMap = useMemo(() => {
    if (!mainMdf) return {};
    return keyBy(mainMdf.fields, (f) => f.fieldId);
  }, [mainMdf]);

  const subTypeByLabel = useMemo(() => {
    return keyBy(mdfsSeparated.subTypes, (s) => s.label);
  }, [mdfsSeparated.subTypes]);

  const validFieldMap = useMemo(() => {
    if (!metadata) return {};
    let allFields: Record<string, MdfField> = { ...fieldMap };
    for (const [key, value] of Object.entries(metadata)) {
      if (fieldMap[key]?.type === FieldTypeEnum.subtype && subTypeByLabel[value as string]) {
        allFields = {
          ...allFields,
          ...keyBy(subTypeByLabel[value as string].fields, (f) => f.fieldId),
        };
      }
    }
    return allFields;
  }, [mdfsSeparated, fieldMap, metadata]);

  const fullFieldMap = useMemo(() => {
    if (!mainMdf) return {};
    return getAllFieldsAndSettings(mainMdf, subTypeByLabel, specificView);
  }, [mdfs, mainMdf, subTypeByLabel, specificView]);

  const errorMap = useMemo(() => {
    if (!metadata) return {};
    return getErrorMap(validFieldMap, metadata);
  }, [metadata, validFieldMap]);

  const errorTooltip: React.ReactElement | undefined = useMemo(() => {
    if (!mainMdf) return;
    const errors = generateTooltipText(errorMap, fullFieldMap);
    if (!errors.length) return undefined;
    return (
      <>
        <div>Please resolve errors:</div>
        <ul style={{ padding: '0 0 0 20px' }}>
          {errors.map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      </>
    );
  }, [errorMap, mainMdf, specificView, fullFieldMap]);

  return { errorMap, validFieldMap, errorTooltip };
};

export default useMdfErrorMap;
