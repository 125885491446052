import React, { useReducer } from 'react';

import InputField from 'components/inputField/InputField';
import { useAuthContext } from 'contexts/AuthContext';

import { useCurrentForm, useLoading, useTempUser } from '../store/atom';

import {
  Form,
  FormContainer,
  FormTitle,
  InfoText,
  LinksWrapper,
  StyledButton,
  StyledLink,
} from './forms-styled';
import { getErrorText } from './utils';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  password: '',
  passwordDescription: 'Type your Password here...',
  severity: 'mandatory',
};

const reducer = (
  state: typeof INITIAL_STATE,
  { type, payload }: { type: string; payload: string },
) => {
  switch (type) {
    case 'updateFirstName':
      return { ...state, firstName: payload };
    case 'updateLastName':
      return { ...state, lastName: payload };
    case 'updatePassword':
      return state.severity === 'error'
        ? {
            ...INITIAL_STATE,
            password: payload,
            firstName: state.firstName,
            lastName: state.lastName,
          }
        : { ...state, password: payload };
    case 'forceResetError':
      return {
        ...state,
        severity: 'error',
        passwordDescription: payload,
      };
    default:
      throw new Error(`Action ${type} is not defined`);
  }
};

const ForceResetPassword = () => {
  const { completeSignup } = useAuthContext();
  const [tempUser] = useTempUser();
  const [loading, setLoading] = useLoading();
  const [, setCurrentForm] = useCurrentForm();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  async function forceReset() {
    if (!tempUser || typeof tempUser !== 'object') return;
    setLoading(true);
    try {
      await completeSignup?.(
        tempUser,
        state.firstName.trim(),
        state.lastName.trim(),
        state.password.trim(),
      );
      setLoading(false);
      setCurrentForm('DEFAULT_LOGIN');
    } catch (error) {
      dispatch({
        type: 'forceResetError',
        payload: getErrorText(error),
      });
      setLoading(false);
    }
  }

  const handleForceReset: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    forceReset();
  };

  return (
    <FormContainer>
      <FormTitle>Reset Password</FormTitle>
      <InfoText>Please reset your temporary password to complete Sign up</InfoText>
      <Form onSubmit={handleForceReset}>
        <InputField
          label="First Name"
          type="text"
          autoComplete="first-name"
          value={state.firstName}
          severity="mandatory"
          description="Type your First Name here..."
          onChange={(value: string) => dispatch({ type: 'updateFirstName', payload: value })}
        />
        <InputField
          label="Last Name"
          type="text"
          autoComplete="last-name"
          value={state.lastName}
          severity="mandatory"
          description="Type your Last Name here..."
          onChange={(value: string) => dispatch({ type: 'updateLastName', payload: value })}
        />
        <InputField
          label="New Password"
          type="password"
          autoComplete="new-password"
          value={state.password}
          severity={state.severity}
          description={state.passwordDescription}
          onChange={(value: string) => dispatch({ type: 'updatePassword', payload: value })}
        />
        <StyledButton
          type="submit"
          disabled={
            state.severity === 'error' ||
            !(state.firstName && state.lastName && state.password) ||
            loading
          }
        >
          SUBMIT
        </StyledButton>
      </Form>
      <LinksWrapper>
        <StyledLink component="button" onClick={() => setCurrentForm('DEFAULT_LOGIN')}>
          Back to Login
        </StyledLink>
      </LinksWrapper>
    </FormContainer>
  );
};

export default ForceResetPassword;
