import { MutableRefObject } from 'react';
import { Descendant, Editor } from 'slate';

import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import { ResourceDetails } from 'hooks/useResourceDetails';
import {
  Asset,
  CustomData,
  CustomElement,
  EditorFontSize,
  EditorValue,
  Instance,
  isObject,
  User,
} from 'types';
import { Form } from 'types/forms/forms';
import { PlatformSectionConfig, PlatformStructure } from 'types/graphqlTypes';
import { NormalizedAsset } from 'utils/normalizeAssetData';

import { ToolbarProps } from './components/toolbar';
import variants from './constants/types/editorVariants';
import { MosAsset } from './utils/convertIntoMosAsset';

export type TextDirection = 'ltr' | 'rtl' | 'auto';

export type EditorVariant = (typeof variants)[keyof typeof variants];

export type PlaceholderTemplate = {
  defaultFormat: string;
  defaultHint: string;
  maxLength: number;
  maxLengthMessage: string;
  characters: string;
  conjunctiveCharacter: string;
  hasDuplicateMessage: string;
};

export type PlaceholderVariables = Instance & {
  rundown: {
    mTitle?: string;
  };
  story: {
    mTitle?: string;
    mPublishingAt?: string;
  };
};

export type PlaceholderConfigs = {
  template?: PlaceholderTemplate | {};
  s3Content: EditorValue | null;
  variables?: PlaceholderVariables | {};
};

export type PlatformSpecificForm = {
  [key: string]: Form;
};

export type AssetInsertType = {
  mId: string;
  mRefId: string;
  src: string;
};

export type CreateAssetType = {
  data: {
    createAssets: Asset[] | null;
  };
};

export const isAssetInsert = (obj: unknown): obj is AssetInsertType => {
  if (!obj) return false;
  return isObject(obj) && 'mId' in obj && 'mRefId' in obj;
};

export const isCreateAsset = (obj: unknown): obj is CreateAssetType => {
  if (!obj) return false;
  if (!isObject(obj)) return false;
  const { data } = obj as CreateAssetType;

  return 'createAssets' in data;
};

export type UpdateInput =
  | { type: ActionTypesEnum.CHANGE; payload: EditorValue }
  | {
      type: ActionTypesEnum.COMMIT_UPDATE;
      payload: EditorValue;
      commitFor: 'asset' | 'userInitiated';
    }
  | {
      type: ActionTypesEnum.CREATE_ASSET;
      payload: {
        document?: CustomElement[] | Descendant[];
        asset: Asset | NormalizedAsset | MosAsset;
      };
    }
  | {
      type: ActionTypesEnum.ASSET_INSERT;
      payload: { document: CustomElement[]; file: File; fileName?: string };
    }
  | { type: ActionTypesEnum.ASSET_REMOVE; payload: { document: CustomElement[]; src?: string } }
  | {
      type: ActionTypesEnum.AUTOMATION_REMOVE;
      payload: { document: CustomElement[]; removedElement?: CustomElement };
    }
  | {
      type: ActionTypesEnum.AUTOMATION_UPDATE;
      payload: { document: CustomElement[] | Descendant[]; updatedData?: CustomData };
    }
  | {
      type: ActionTypesEnum.BLOCK_INSERT;
      payload: {
        document: CustomElement[];
        insertedElement: CustomElement;
      };
    }
  | {
      type: ActionTypesEnum.BLOCK_REMOVE;
      payload: {
        document: CustomElement[];
        removedElement: CustomElement;
      };
    }
  | {
      type: ActionTypesEnum.AUTOMATION_INSERT;
      payload: {
        document: CustomElement[];
        insertedElement: CustomElement;
      };
    };

export type Update = (
  input: UpdateInput,
) => Promise<AssetInsertType> | Promise<CreateAssetType> | Promise<void>;

/* Editor context related props */
export interface EditorContextProps {
  update?: Update;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  variant?: EditorVariant;
  platformId?: string;
  config?: PlatformSectionConfig[];
  resourceDetails?: ResourceDetails;
  getPlaceholderConfig?: () => PlaceholderConfigs;
  users?: User[];
  thumbnail?: string | null;
  isAllowed?: boolean;
  onCmsEditing?: () => void;
  isPublished?: boolean;
  isCmsBlock?: boolean;
  onDone?: (keepFocus?: boolean) => void;
  isLockedByAnotherUser?: boolean;
  doLock?: () => void;
  doSave?: (doStayLocked: boolean) => Promise<void>;
  editorFontSize?: EditorFontSize;
  withSignedUrl?: boolean;
  allowVideoInPhotogallery?: boolean;
  formsForThisPlatform?: PlatformSpecificForm;
  onSave?: () => Promise<void>;
  onHotKeys?: (
    event: React.KeyboardEvent,
    callback?: () => void | Promise<void>,
  ) => void | Promise<void>;
}

/* props that are forwarded directly to editor context */
type ForwardedContextProps = Omit<
  EditorContextProps,
  | 'isLockedByAnotherUser'
  | 'onDone'
  | 'containerRef'
  | 'formsForThisPlatform'
  | 'config'
  | 'getPlaceholderConfig'
  | 'allowVideoInPhotogallery'
>;

/* Editor specific props which has some similar props as context props */
export interface EditorProps extends ForwardedContextProps {
  background?: string;
  autoFocus?: boolean;
  height?: number | string;
  placeholder?: string;
  readOnly?: boolean;
  renderToolbar?: (() => null) | ((props: ToolbarProps) => React.ReactNode);
  value?: EditorValue | null;
  shouldResetSelection?: boolean;
  width?: string | number;
  toolbarPosition?: 'top' | 'bottom' | 'none';
  onDone?: (value: EditorValue) => void;
  onSave?: () => Promise<void>;
  showHoveringTooltip?: boolean;
  padding?: number;
  onFocus?: () => void;
  onBlur?: () => void;
  showDoneButton?: boolean;
  enableEmoji?: boolean;
  keepFocus?: boolean;
  enableEditorCommand?: boolean;
  showSidepanelButton?: boolean;
  writeLock?: boolean;
  readLock?: boolean;
  suppressChangeEvent?: boolean;
  fallbackText?: string;
  direction?: TextDirection;
  hostReadSpeed?: number;
  getPlaceholderConfigs?: () => PlaceholderConfigs;
  // default value unknown
  setEditor?: (editor: Editor) => void;
  platformStructure?: PlatformStructure;
  platformKind?: string;
}

/* Helper functions */
export const calculatedHW = (value: string | number) => {
  if (typeof value === 'string') {
    // Handle percentage/pixel/calc values
    if (value.endsWith('%') || value.endsWith('px') || value.startsWith('calc')) return value;
  } else if (typeof value === 'number') return `${value}px`; // Convert numbers to pixel values

  return '100%';
};

export const getDefaultPlaceholderConfigs = () => ({
  template: {},
  s3Content: null,
  variables: {},
});
