import styled from '@emotion/styled';
import transientOptions from 'theme/helpers';

import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';

const getBorderColor = ({ $isSelected, theme }) => {
  if ($isSelected) {
    return theme.palette.dina.onFocus;
  } else {
    return theme.palette.dina.buttonBorderOutlined;
  }
};

const getHoverColor = ({ $isOver }) => {
  if ($isOver) {
    return 'red';
  } else {
    return 'transparent';
  }
};

export const DroppableDiv = styled('div', transientOptions)`
  border: 1px solid ${({ $isOver = false }) => getHoverColor({ $isOver })};
`;

const BoxWrapper = styled('div', transientOptions)`
  box-sizing: border-box;
  border: 1px solid ${({ theme, $isSelected = false }) => getBorderColor({ $isSelected, theme })};

  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    .primaryAutomationMenu {
      opacity: ${({ $readOnly }) => ($readOnly ? 0 : 1)};
      width: ${({ $readOnly }) => ($readOnly ? 0 : '32px')};
    }
  }
`;

const Icon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const PlaceholdersWrapper = styled('div')`
  width: min-content;
  min-width: 60px;
  max-width: 90px;
  flex-shrink: 0;
  height: 100%;
  padding: 0.25rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
  overflow: hidden;
`;

const RootWrapper = styled('div', transientOptions)`
  pointer-events: ${(props) => (props.$readOnly ? 'none' : 'all')};
  padding: 8px;
  width: 100%;
  container-type: inline-size;
`;

const Thumbnail = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ThumbnailWrapper = styled.div`
  width: 71px;
  min-width: 71px;
  max-width: 71px;
  height: 100%;
  position: relative;
  user-select: none;
  display: flex;
  pointer-events: all;
  cursor: ${({ $showCursor }) => ($showCursor ? 'pointer' : 'default')};
`;

const TitleWrapper = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  transition: width 0.3s ease;
  will-change: width;
  overflow: hidden;

  @container (max-width: 600px) {
    width: 210px;
  }

  @container (max-width: 540px) {
    width: 150px;
  }

  @container (max-width: 480px) {
    width: 90px;
  }
`;

const OpenIcon = styled(Open)`
  path {
    fill-opacity: 0.54;
  }

  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export {
  BoxWrapper,
  Icon,
  PlaceholdersWrapper,
  RootWrapper,
  Thumbnail,
  ThumbnailWrapper,
  TitleWrapper,
  OpenIcon,
};
