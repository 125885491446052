import { memo } from 'react';
import styled from '@emotion/styled';

import { Button } from 'components/buttons';
import Tooltip from 'components/tooltip';

import { CellProps } from '../fields';

const Cell = styled('span')`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

function SubTypeCell({ value, showPreview }: Readonly<CellProps>) {
  return (
    <Tooltip title="View fields in this group">
      <Button onClick={showPreview} asChild>
        <Cell>{typeof value === 'string' ? value : ''}</Cell>
      </Button>
    </Tooltip>
  );
}

export default memo(SubTypeCell);
