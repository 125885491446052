import { useCallback } from 'react';

import { useSelectedInstances } from 'screens/rundown/store/screen';
import {
  ToolbarButton,
  toolbarButtonIds,
  useRundownToolbarButtons,
} from 'screens/rundown/store/toolbar';

const useSelectRundownInstance = () => {
  const [, setSelectedIds] = useSelectedInstances();
  const [rundownToolbarButtons, setRundownToolbarButtons] = useRundownToolbarButtons();

  const selectRundownInstance = useCallback((instanceIds: string[]) => {
    setSelectedIds(instanceIds);
    const newRundownToolbarButtons = rundownToolbarButtons.reduce((accumulator, button) => {
      if (button?.id !== toolbarButtonIds.EDITOR) return [...accumulator, button];
      return [...accumulator, { ...button, selected: true }];
    }, [] as ToolbarButton[]);
    setRundownToolbarButtons(newRundownToolbarButtons);
  }, []);

  return { selectRundownInstance };
};

export default useSelectRundownInstance;
