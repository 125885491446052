import { ApolloCache, useMutation } from '@apollo/client';

import { UPDATE_PLATFORM_WORKFLOW } from 'operations/mutations/updatePlatformWorkflow';
import GET_PLATFORMS from 'operations/queries/getPlatforms';
import { Platform } from 'types';
import { UpdatePlatformWorkflowInput, WorkflowSettings } from 'types/graphqlTypes';

export type UpdatePlatformWorkflowReturnType = {
  updatePlatformWorkflow: {
    mId: string;
    mRefId: string;
    mType: string;
    mTitle: string;
    workflowSettings: WorkflowSettings;
  }[];
};

const useUpdatePlatformWorkflows = () => {
  const [mutation, { loading, data, error }] = useMutation<
    UpdatePlatformWorkflowReturnType,
    {
      input: UpdatePlatformWorkflowInput;
    }
  >(UPDATE_PLATFORM_WORKFLOW);

  const updateCache = (
    cache: ApolloCache<object>,
    { data: newData }: { data?: UpdatePlatformWorkflowReturnType | null },
  ) => {
    if (!newData?.updatePlatformWorkflow) return;

    const existingPlatforms = cache.readQuery<{ getPlatforms: Platform[] }>({
      query: GET_PLATFORMS,
    });

    if (!existingPlatforms?.getPlatforms) return;

    const updatedPlatforms = newData.updatePlatformWorkflow;

    const newPlatforms = existingPlatforms.getPlatforms.map((ePlatform) => {
      const updatedPlatform = updatedPlatforms.find(
        (uPlatform) => uPlatform.mRefId === ePlatform.mRefId,
      );

      return updatedPlatform ? { ...ePlatform, ...updatedPlatform } : ePlatform;
    });

    cache.writeQuery({
      query: GET_PLATFORMS,
      data: {
        getPlatforms: newPlatforms,
      },
    });
  };

  const updatePlatformWorkflows = async ({
    updatedWorkflows,
  }: {
    updatedWorkflows: Record<string, WorkflowSettings>;
  }) => {
    try {
      const result = await mutation({
        variables: {
          input: {
            updatedWorkflows: JSON.stringify(updatedWorkflows),
          },
        },
        update: updateCache,
      });
      return result?.data?.updatePlatformWorkflow;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    }
  };

  return { updatePlatformWorkflows, loading, data: data?.updatePlatformWorkflow, error };
};

export default useUpdatePlatformWorkflows;
