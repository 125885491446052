import { useCallback, useContext, useMemo } from 'react';
import { ReactEditor } from 'slate-react';

import { TextDirection } from 'components/editor/types';
import { DEFAULT_EDITOR_PLACEHOLDER } from 'components/editor/utils/constants';
import ConfigContext from 'contexts/configContext';
import { useInstanceMolecule } from 'features/instance/store/instance';
import useImageUrl from 'hooks/useImageUrl';
import useSettingsValue from 'hooks/useSettingsValue';
import defaultStateValues from 'screens/planning/components/status/utils/statusBoards/defaultStateValues';
import { ColorVariants, KanbanStatus } from 'types';
import getIdentifier from 'utils/instance/getAccountIdentifier';
import variants from 'utils/instance/variants';

import { getMessage, getVariant } from '../utils';

import useInstancePermissions from './useInstancePermissions';

const useInstanceViewUtils = () => {
  const [getSettingsValue] = useSettingsValue();
  const { kanbanBoardStates = [] as KanbanStatus[] } = useContext(ConfigContext);

  const {
    useInstanceValue,
    useView,
    useMetadataView,
    useShowCmsEditDialog,
    usePublishingPointValue,
    useEditor,
    usePlatform,
    usePlatformVariant,
  } = useInstanceMolecule();
  const { canUpdateInstance } = useInstancePermissions();

  const instance = useInstanceValue();
  const publishingPoint = usePublishingPointValue();
  const [editor, setEditor] = useEditor();
  const [view, setView] = useView();
  const [showMetadata, setShowMetadata] = useMetadataView();
  const [openCmsEditDialog, setOpenCmsEditDialog] = useShowCmsEditDialog();
  const [platform] = usePlatform();
  const [platformVariant] = usePlatformVariant();
  const { previewEndpoint, embeddedEndpoint, state, message } =
    instance?.mProperties?.provider || {};

  const variant = getVariant(instance?.mProperties?.platform ?? '');
  const textDirection = (getSettingsValue('app.textDirection') ?? 'auto') as TextDirection;

  const kanbanState = kanbanBoardStates.find((s) => s.id === instance?.mState);
  const isFailedState = instance?.mState === 'failed';
  const backgroundColor = (kanbanState?.backgroundColor ||
    defaultStateValues.backgroundColor) as keyof ColorVariants;
  const opacity = kanbanState?.opacity || defaultStateValues.opacity;
  const title = instance?.mTitle;

  const isYoutubeInstance = publishingPoint === variants.YOUTUBE;
  const isTwitterInstance = publishingPoint === variants.TWITTER;
  const isFacebookInstance = publishingPoint === variants.FACEBOOK;
  const isInstagramInstance = publishingPoint === variants.INSTAGRAM;
  const isCMSInstance = publishingPoint === variants.CMS;
  const isLinearInstance = variant === variants.LINEAR;
  const placeholder =
    canUpdateInstance && variant !== variants.CMS ? DEFAULT_EDITOR_PLACEHOLDER : '';

  const platformStructure =
    platformVariant?.platformStructure ?? platform?.mProperties?.platformStructure;

  const isCmsBlock = variant === variants.CMS && platformStructure?.variant === 'blocks';

  const editInExternalTab =
    variant === variants.CMS && platformStructure?.variant === 'editInExternalTab';

  const footerMessage = getMessage(variant, state ?? '', message ?? '');

  const accountIdentifier = useMemo(() => {
    if (instance && instance?.mProperties?.account?.accountTitle) {
      return getIdentifier(
        instance?.mProperties?.platform,
        instance?.mProperties?.account?.accountTitle,
      );
    }
    return '';
  }, [instance]);

  const thumbnail = useImageUrl(
    isLinearInstance && instance?.mProperties?.account?.accountLogo
      ? instance?.mProperties?.account?.accountLogo
      : instance?.mThumbnailKey ?? '',
  );

  const showTwitterEmbed = useMemo(
    () => isTwitterInstance && !!previewEndpoint,
    [isTwitterInstance, previewEndpoint],
  );

  const showYoutubeEmbed = useMemo(
    () => isYoutubeInstance && !!embeddedEndpoint,
    [embeddedEndpoint, isYoutubeInstance],
  );

  const showFacebookEmbed = useMemo(
    () => isFacebookInstance && !!embeddedEndpoint,
    [embeddedEndpoint, isFacebookInstance],
  );

  const showInstagramEmbed = useMemo(
    () => isInstagramInstance && !!embeddedEndpoint,
    [embeddedEndpoint, isInstagramInstance],
  );

  const isEmbedPresent = useMemo(
    () => showTwitterEmbed || showYoutubeEmbed || showFacebookEmbed || showInstagramEmbed,
    [showFacebookEmbed, showInstagramEmbed, showTwitterEmbed, showYoutubeEmbed],
  );

  const resetSelection = () => {
    if (editor) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const { deselect, blur } = ReactEditor;
      if (editor.selection) {
        deselect(editor);
        blur(editor);
      }
    }
  };

  const toggleView = useCallback(() => {
    setView(view === 'edit' ? 'preview' : 'edit');
  }, [setView, view]);

  const toggleMetadataView = useCallback(() => {
    setShowMetadata(!showMetadata);
  }, [setShowMetadata, showMetadata]);

  const onSetEditor = useCallback(
    (value: ReactEditor) => {
      setEditor(value);
    },
    [setEditor],
  );

  const onCmsEditingClick = useCallback(() => {
    if (editInExternalTab) {
      window.open(embeddedEndpoint, '235711131719');
    } else {
      setOpenCmsEditDialog(!openCmsEditDialog);
    }
  }, [editInExternalTab, embeddedEndpoint, setOpenCmsEditDialog, openCmsEditDialog]);

  return {
    view,
    setView,
    toggleView,
    showMetadata,
    isFailedState,
    backgroundColor,
    opacity,
    title,
    toggleMetadataView,
    onCmsEditingClick,
    resetSelection,
    thumbnail,
    isCMSInstance,
    isLinearInstance,
    isTwitterInstance,
    onSetEditor,
    placeholder,
    textDirection,
    variant,
    isCmsBlock,
    isEmbedPresent,
    showFacebookEmbed,
    showTwitterEmbed,
    showInstagramEmbed,
    showYoutubeEmbed,
    footerMessage,
    accountIdentifier,
  };
};

export default useInstanceViewUtils;
