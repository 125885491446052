import * as React from 'react';
import styled from '@emotion/styled';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { dialogBoxShadow } from 'theme/utils/commonStyles';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const Close = PopoverPrimitive.Close;

const Content = styled(PopoverPrimitive.Content)`
  ${dialogBoxShadow};
  z-index: 1350;
  min-width: 360px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  outline: none;
  padding-block: 4px;
  ul,
  li {
    margin: 0;
  }
  ul {
    list-style-type: none;
    padding-inline: 0;
  }
  li {
    padding-inline: 8px;
  }

  &[data-state='open'] {
    animation: slideDown 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-side='bottom'] {
    transform-origin: top;
  }
  &[data-side='left'] {
    transform-origin: right;
  }
  &[data-side='right'] {
    transform-origin: left;
  }
  &[data-side='top'] {
    transform-origin: bottom;
  }
  @keyframes slideDown {
    from {
      transform: translateY(-0.5rem);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .MuiDivider-root {
    padding: 0;
    margin-block: 4px;
  }
`;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <Content ref={ref} sideOffset={4} align="start" {...props} />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export type PopoverProps = PopoverPrimitive.PopoverProps;
export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor, Close };
