import styled from '@emotion/styled';
import MuiListItem from '@material-ui/core/ListItem';
import MuiModal from '@material-ui/core/Modal';

import transientOptions from 'theme/helpers';

interface WrapperType {
  $hovered?: boolean;
  $dense?: boolean;
}

export const Wrapper = styled('div', transientOptions)<WrapperType>`
  width: 100%;
  max-width: 100%;
  height: 100%;
  border: ${({ $hovered, theme }) =>
    $hovered ? `1px solid ${theme.palette.dina.statusWarning}` : 'none'};
`;

interface HeaderType {
  $dense?: boolean;
  $darker?: boolean;
}

export const Header = styled('button', transientOptions)<HeaderType>`
  border: none;
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 0 2px;
  border-bottom: 1px solid transparent;
  border-radius: ${({ $dense }) => !$dense && '8px'};
  overflow: hidden;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
  background-color: ${({ theme, $darker }) =>
    $darker ? theme.palette.dina.dividerDark : theme.palette.dina.backgroundResting};
  &.group-open {
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  }
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
    border-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
  }
  :focus-visible {
    outline: 2px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
  }

  &[aria-expanded='true'] {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const LeftSideItems = styled('div')`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  gap: 4px;
`;

export const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  :focus {
    outline: none;
  }
`;

export const GroupHeader = styled(MuiListItem)`
  height: 40px;
  padding: 0;
  padding-right: 8px;
  border-bottom: 1px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.dina.backgroundResting};
  &.group-open {
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  }
`;

export const Arrow = styled('span')`
  display: flex;
  height: 100%;
  justify-content: center;
`;
