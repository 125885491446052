import { FormEventHandler, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import InputField from 'components/inputField/InputField';
import { useAuthContext } from 'contexts/AuthContext';

import { useCurrentForm, useIsReset, useLoading, useTempUser } from '../store/atom';

import {
  Form,
  FormContainer,
  FormTitle,
  LinksWrapper,
  StyledButton,
  StyledLink,
  Subtitle,
} from './forms-styled';
import { getErrorText } from './utils';

interface State {
  username: string;
  password: string;
  severity: string;
  usernameDescription?: string;
  passwordDescription?: string;
}

const INITIAL_STATE: State = {
  username: '',
  password: '',
  severity: 'mandatory',
};

const reducer = (state: State, { type, payload }: { type: string; payload: string }) => {
  switch (type) {
    case 'updateUsername':
      return state.severity === 'error'
        ? { ...INITIAL_STATE, username: payload, password: state.password }
        : { ...state, username: payload };
    case 'updatePassword':
      return state.severity === 'error'
        ? { ...INITIAL_STATE, username: state.username, password: payload }
        : { ...state, password: payload };
    case 'loginError':
      return {
        ...state,
        severity: 'error',
        usernameDescription: payload,
        passwordDescription: payload,
      };
    default:
      throw new Error(`Action ${type} is not defined`);
  }
};

const DefaultLogin = () => {
  const { login } = useAuthContext();
  const [isReset, setIsReset] = useIsReset();
  const [tempUser, setTempUser] = useTempUser();
  const [loading, setLoading] = useLoading();
  const [, setCurrentForm] = useCurrentForm();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { t } = useTranslation();

  async function doLogin() {
    setLoading(true);
    try {
      const cognitoUser = await login?.(state.username.trim(), state.password.trim());
      if (cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setTempUser(cognitoUser);
        setLoading(false);
        setCurrentForm('FORCE_RESET_PASSWORD');
      }
      if (isReset) setIsReset(false);
      setLoading(false);
    } catch (error) {
      dispatch({ type: 'loginError', payload: getErrorText(error) });
      setLoading(false);
    }
  }
  const handleLogin: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    void doLogin();
  };

  useEffect(() => {
    if (isReset) dispatch({ type: 'updateUsername', payload: tempUser as string });
  }, [isReset, tempUser]);

  return (
    <FormContainer>
      <FormTitle>{t('loginPage.body.loginHeader', 'Log in')}</FormTitle>
      <Subtitle>
        {t('loginPage.body.welcomeText', 'Welcome to Dina')},{' '}
        {t('loginPage.body.loginText', 'Please log in below.')}
      </Subtitle>
      <Form onSubmit={handleLogin}>
        <InputField
          autoFocus
          label={t('loginPage.body.usernameLabel', 'Username')}
          type="text"
          autoComplete="username"
          value={state.username}
          severity={state.severity}
          description={
            state.severity !== 'error'
              ? t('loginPage.body.usernameDescription', 'Type your Username here...')
              : state.usernameDescription
          }
          onChange={(value: string) => dispatch({ type: 'updateUsername', payload: value })}
        />
        <InputField
          label={t('loginPage.body.passwordLabel', 'Password')}
          type="password"
          autoComplete={isReset ? 'new-password' : 'password'}
          value={state.password}
          severity={state.severity}
          description={
            state.severity !== 'error'
              ? t('loginPage.body.passwordDescription', 'Type your Password here...')
              : state.passwordDescription
          }
          onChange={(value: string) => dispatch({ type: 'updatePassword', payload: value })}
        />
        <StyledButton
          type="submit"
          disabled={state.severity === 'error' || !(state.username && state.password) || loading}
        >
          {t('loginPage.body.loginButtonText', 'LOG IN')}
        </StyledButton>
      </Form>
      <LinksWrapper>
        <StyledLink component="button" onClick={() => setCurrentForm('FORGOT_PASSWORD')}>
          {t('loginPage.body.forgotPasswordText', 'Forgot your password?')}
        </StyledLink>
      </LinksWrapper>
    </FormContainer>
  );
};

export default DefaultLogin;
