import { useCallback, useMemo } from 'react';
import { TableMeta } from '@tanstack/react-table';
import { Dictionary } from 'lodash';

import useToast from 'components/toast/useToast';
import { ParsedOrderType } from 'features/mdf/useGetMdfColumns';
import { useDeleteOrderAtom } from 'features/orderForm/components/OrderGridFull';
import useGoToOrderResource from 'features/orderForm/components/useGoToOrderResource';
import { getAllStatusOptions } from 'features/orderForm/utils';
import useUpdateOrder from 'screens/space/api/useUpdateOrder';
import { useSetPreview } from 'store/preview';
import { Metadata, NewFieldValue, Order, ResourceType } from 'types/forms/forms';
import { FieldValue, MemberTypeEnum } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

interface UseGetTableMetaProps {
  orderFormMap: Dictionary<OrderFormMemberType>;
  closeDialog?: () => void;
}

interface UpdateObjectType {
  id: string;
  resourceId: string;
  fieldId: string;
  value: FieldValue;
}

/** Create an object to update a single field in an order */
const getUpdateOrderObject = ({ id, resourceId, fieldId, value }: UpdateObjectType) => {
  if (fieldId === 'mOwner' || fieldId === 'mAssignee' || fieldId === 'mStatus') {
    return {
      mId: id,
      mResourceId: resourceId,
      [fieldId]: value,
    };
  }

  const partialUpdate: Metadata = { [fieldId]: value };
  return {
    mId: id,
    mResourceId: resourceId,
    metadata: JSON.stringify(partialUpdate),
  };
};

/** Enriches the table with additional functionality. */
export default function useGetTableMeta({ orderFormMap, closeDialog }: UseGetTableMetaProps) {
  const statusOptions = getAllStatusOptions(orderFormMap);
  const openPreview = useSetPreview();
  const { errorToast } = useToast();
  const [, setOrderToDelete] = useDeleteOrderAtom();
  const { goToResource: navigate } = useGoToOrderResource();
  const { updateOrder } = useUpdateOrder();

  /** Show the order preview */
  const setPreview = useCallback(
    (row: ParsedOrderType) => {
      if (row) {
        openPreview({
          mId: row?.mResourceId,
          mRefId: row.mId,
          mTitle: orderFormMap[row?.mFormId]?.mDescription,
          mType: MemberTypeEnum.Order,
          mResourceType: row?.mResourceType as MemberTypeEnum,
          mUpdatedAt: row.mUpdatedAt,
          mCreatedAt: row.mCreatedAt,
        });
      }
    },
    [openPreview, orderFormMap],
  );

  /** Delete the order */
  const deleteOrder = useCallback((mId: string, mResourceId: string) => {
    setOrderToDelete({ mId, mResourceId });
  }, []);

  /** Navigate to the resource connected to the order */
  const goToResource = useCallback((id: string, mResourceType: ResourceType) => {
    navigate(id, mResourceType)
      .then(() => {
        if (closeDialog) closeDialog();
      })
      .catch(errorToast);
  }, []);

  /** Update individual metadata fields of an order
   *
   * @param order - The order to update
   * @param values - An array of field values to update. For orders,
   * this array will always have one element.
   */
  const updateData = useCallback((order: ParsedOrderType, values: NewFieldValue[]) => {
    const { fieldId, value } = values[0];

    updateOrder(
      getUpdateOrderObject({
        id: order.mId,
        resourceId: order.mResourceId,
        fieldId,
        value,
      }),
    ).catch(errorToast);
  }, []);

  /** The table meta object, containing necessary functions */
  // @ts-expect-error - fix: combine order and member previews.
  const meta: TableMeta<Order> = useMemo(() => {
    return {
      emptyStateText: 'No orders found',
      statusOptions,
      updateData,
      deleteOrder,
      goToResource,
      setPreview,
    };
  }, [updateData]);

  return meta;
}
