import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Storage } from '@aws-amplify/storage';

import { useForcedLogout } from 'store';
import { EditorValue } from 'types/editor';
import { replaceTab } from 'utils/content/contentUtil';
import useLogger from 'utils/useLogger';

import { createImageUrl, useIsProdValid } from './useImageUrl';

type SetLoading = Dispatch<SetStateAction<boolean>>;

/**
 * Fetches and processes content based on a provided key.
 *
 * @param contentKey - The key to identify the content to retrieve.
 * @param isProdValid - A flag indicating whether to use a production-valid URL.
 * @param errorLogger - A function to log errors during content retrieval.
 * @param setLoading - A function to control a loading indicator (optional).
 * @returns A Promise that resolves to the normalized content data or null on error.
 */
export const getContent = async (
  contentKey: string,
  isProdValid: boolean,
  errorLogger: (error: Error) => void,
  setLoading: SetLoading | undefined,
  setForcedLogOut?: (value: number) => void,
) => {
  if (setLoading) setLoading(true);

  try {
    const validUrl = isProdValid
      ? createImageUrl(contentKey)
      : await Storage.get(contentKey, {
          customPrefix: { public: '' },
        });

    const response = await fetch(validUrl);
    if (response.status === 401 || response.status === 403) {
      setForcedLogOut?.(response.status);
    }

    const jsonResponse = response.status === 200 ? ((await response.json()) as EditorValue) : null;
    const normalizedData = replaceTab(jsonResponse);

    if (setLoading) setLoading(false);
    return normalizedData;
  } catch (err: unknown) {
    if (err instanceof Error) errorLogger(err);

    if (setLoading) setLoading(false);
    return null;
  }
};

/**
 * A custom hook that fetches and manages content using `getContent`.
 *
 * @param key - The key to identify the content to retrieve (optional).
 * @param skip - A flag to skip fetching content initially (default: false).
 * @returns An object containing `data`, `loading`, and `refetch` functions.
 *   - `data`: The retrieved and processed content data (initially null).
 *   - `loading`: A flag indicating whether content is being fetched.
 *   - `refetch`: A function to manually trigger content refetching.
 */
const useContentResolver = (key: string | undefined, skip = false, resetData = true) => {
  const isProdValid = useIsProdValid();
  const logger = useLogger('useContentResolver');
  const [, setShowForcedLogout] = useForcedLogout();

  const [data, setData] = useState<EditorValue | null>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const subscribed = useRef<string | null>(key ?? null);

  const fetchContent = useCallback(async () => {
    if (!key || !subscribed.current) return null;
    setLoading(true);

    try {
      const result = await getContent(
        key,
        isProdValid,
        logger.log,
        setLoading,
        setShowForcedLogout,
      );
      if (subscribed.current === key) {
        setData(result);
        return result;
      }
    } catch (err) {
      setData(null);
      if (err instanceof Error) {
        setError(err.message);
        logger.log(err);
      }
    } finally {
      setLoading(false);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  useEffect(() => {
    subscribed.current = key ?? null;

    if (!skip && key) {
      fetchContent().then(
        () => {},
        () => {},
      );
    }

    return () => {
      subscribed.current = null;
      if (resetData) setData(null);
    };
  }, [key, skip, fetchContent, resetData]);

  return { data, loading, error, refetch: fetchContent };
};

export default useContentResolver;
