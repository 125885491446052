import { Document, Page, Text, View } from '@react-pdf/renderer';

import { BlockWithLabelAndMdf, MdfsSeparated } from 'api/mdfBlocks/types';
import useDateTimeUtils from 'hooks/useDateTimeUtils';
import { EditorValue, Note } from 'types';
import { OrderWithMdf } from 'types/forms/forms';
import { MemberType, OptionList } from 'types/graphqlTypes';

import CommonDocFooter from '../components/CommonDocFooter';
import { styles } from '../styles';

import { getDocumentComponent } from './utils';

const NoteDocHeader = ({ note }: { note: Note }) => {
  const { format } = useDateTimeUtils();
  const { mTitle, mType, mCreatedAt } = note;

  return (
    <View style={styles.header} fixed>
      <Text style={styles.name}>{mType}</Text>
      <Text style={styles.title}>{mTitle || 'Untitled'}</Text>
      <Text style={styles.scheduleInfo}>
        Created At: {format(mCreatedAt, 'MMM d yyyy, HH:mm:ss (XXX)')}
      </Text>
      <View style={[styles.horizontalLine, { paddingTop: '8' }]} />
    </View>
  );
};

const NoteContents = ({
  content,
  blocks,
  orders,
  mdfsSeparated,
  relationMembers,
  contacts,
  groups,
  optionLists,
}: Omit<Props, 'note'>) => {
  const { document = [] } = content;

  if (document && document.length > 0) {
    return (
      <View style={[styles.column, styles.content]}>
        {document.map((doc) => {
          return getDocumentComponent({
            doc,
            blocks,
            orders,
            mdfsSeparated,
            relationMembers,
            contacts,
            groups,
            optionLists,
          });
        })}
      </View>
    );
  }

  return null;
};

interface Props {
  note: Note;
  content: EditorValue;
  blocks: BlockWithLabelAndMdf[];
  orders: OrderWithMdf[];
  mdfsSeparated: MdfsSeparated;
  relationMembers: MemberType[];
  contacts: MemberType[];
  groups: string[];
  optionLists: OptionList[];
}

const NotePrintDoc = ({
  note,
  content,
  blocks,
  orders,
  mdfsSeparated,
  relationMembers,
  contacts,
  groups,
  optionLists,
}: Props) => (
  <Document>
    <Page style={styles.body}>
      <NoteDocHeader note={note} />
      <NoteContents
        content={content}
        blocks={blocks}
        orders={orders}
        mdfsSeparated={mdfsSeparated}
        relationMembers={relationMembers}
        contacts={contacts}
        groups={groups}
        optionLists={optionLists}
      />
      <CommonDocFooter />
    </Page>
  </Document>
);

export default NotePrintDoc;
