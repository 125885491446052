import { useMemo } from 'react';

import { isSupportedMetadata, useGetMdfs } from 'api/mdf/useGetMdfs';
import useMetadata from 'hooks/useMetadata';
import { Metadata } from 'types/forms/forms';
import { MemberType, ViewTypes } from 'types/graphqlTypes';

import { MdfEditor } from './MdfEditor';

export interface MemberEditorProps {
  view: ViewTypes;
  member: Required<Pick<MemberType, 'mId' | 'mRefId' | 'mType' | 'metadata'>>;
  readonly?: boolean;
}

const getParsedMD = (val?: string | null) => {
  try {
    return JSON.parse(val ?? '{}') as Metadata | null | undefined;
  } catch (err) {
    return null;
  }
};

/**
 * An MDF editor that will load the metadata of the provided member.
 *
 * Any change a user does is automatically saved.
 */
export function MemberMdfEditor({ view, member, readonly }: Readonly<MemberEditorProps>) {
  const { mdfsByMType } = useGetMdfs();
  const parsedMetadata = useMemo(() => {
    return getParsedMD(member.metadata) ?? {};
  }, [member.metadata]);

  const mdf = useMemo(() => {
    if (isSupportedMetadata(member.mType)) {
      return mdfsByMType[member.mType];
    }
  }, [mdfsByMType, member]);

  const { metadata, errorMap, updateFieldValues } = useMetadata(
    mdf,
    parsedMetadata,
    view,
    member,
    member.mId,
  );

  return (
    mdf && (
      <MdfEditor
        fields={mdf.fields}
        defaultLayoutSettings={mdf.views.default}
        layoutSettings={mdf.views[view]}
        view={view}
        metadata={metadata}
        permissions={mdf.permissions}
        updateFieldValue={updateFieldValues}
        errorMap={!readonly ? errorMap : undefined}
        readonly={readonly}
      />
    )
  );
}
