/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { noop } from 'lodash';
import findKey from 'lodash/findKey';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { v4 } from 'uuid';

import { elementTypes } from 'components/editor/constants/types';
import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import { Update } from 'components/editor/types';
import { CustomData, CustomEditor, CustomElement } from 'types';

const { primaryTypes, properties } = elementTypes;

const defaultData = {
  automationType: 'PRIMARY',
  metaData: [],
  secondaryItems: [],
  templateVariant: 'VARIANT',
};

const { select, collapse, insertNodes } = Transforms;

interface Props {
  editor: CustomEditor;
  update: Update;
  isNewline?: boolean;
  data?: CustomData;
  droppedOnElement: CustomElement;
  elementToInsert: CustomElement;
}

export const createPrimaryAutomationElement = (data: CustomData) => {
  return {
    type: 'package',
    data: {
      ...defaultData,
      ...data,
      itemId: v4(),
      typeId: properties.package.id,
      templateType: findKey(primaryTypes, (value) => value === 'package'),
    },
    children: [{ text: '' }],
  };
};

export const insertPrimaryAutomationElementV2 = ({
  editor,
  update,
  elementToInsert,
  droppedOnElement,
}: Readonly<Props>) => {
  const path = ReactEditor.findPath(editor, droppedOnElement);
  select(editor, path);
  collapse(editor, { edge: 'end' });
  insertNodes(editor, elementToInsert);
  update({
    type: ActionTypesEnum.AUTOMATION_INSERT,
    payload: { document: editor.children as CustomElement[], insertedElement: elementToInsert },
  }).catch(noop);

  return editor;
};
