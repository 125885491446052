import gql from 'graphql-tag';

export default gql`
  query GetMember($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mTitle
      mThumbnailKey
      mType
      mStoryId
      mCreatedAt
      mUpdatedAt
      mUpdatedById
      mPublishingAt
      mContentKey
      mDefaultContentKey
      locked
      mState
      mTemplateId
      isTemplateInstance
      metadata
      mProperties {
        __typename
        ... on PlatformType {
          id
          platform
          platformKind
          provider {
            id
            requestId
            state
            message
            embeddedEndpoint
            previewEndpoint
            updateEndpoint
          }
          account {
            id
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            orderType
          }
        }
      }
      items {
        itemId
        title
        templateType
        templateVariant
        iconUrl
        subItems {
          itemId
          title
          templateType
          templateVariant
          iconUrl
        }
      }
      mMetaData {
        key
        value
        manual
        autoValue
      }
      mAssignedMembers {
        mId
        mType
      }
      mRelatedMembers
    }
  }
`;
