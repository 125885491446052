import { lazy, Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useLoadStudios from 'api/useLoadStudios';
import { AddInstanceDialog } from 'components/addInstanceDialog/AddInstanceDialog';
import AddMemberDialog from 'components/addMemberDialog';
import CommandMenu from 'components/command/Command';
import { MemberContextMenu } from 'components/contextMenu/MemberContextMenu';
import { PluginSearchContextMenu } from 'components/contextMenu/PluginSearchContextMenu';
import CreateNewDialog from 'components/createNewV3/CreateNewDialog';
import SpaceDialog from 'components/createNewV3/SpaceDialog';
import { AsyncConfirmationDialog } from 'components/dialogs/CommonDialogs';
import EditFormDialog from 'components/editFormDialog';
import EditPermissionDialog from 'components/editMdfDialog/EditPermissionDialog';
import { EditStringDialog } from 'components/editStringDialog/EditStringDialog';
import ReloadOnAuthError from 'components/errorBoundary/ReloadOnAuthError';
import OrderForms from 'components/listItems/orderForms';
import LoadingIndicator from 'components/loadingIndicator';
import { EditFieldValueDialog } from 'components/mdfEditor/EditFieldValueDialog';
import { EditMetadataDialog } from 'components/mdfEditor/EditMetadataDialog';
import { DatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import OrderFormDialog, { MyOrdersDialog, OrdersDialog } from 'components/orderFormDialog';
import { ToastComponent } from 'components/toast/Toast';
import TreeChoiceDialog from 'components/treeChoiceDialog/TreeChoiceDialog';
import useDailyNoteSubscription from 'features/dailyNote/hooks/useDailyNoteSubscription';
import Portal from 'features/portal';
import Preview from 'features/preview';
import useCookieCleanup from 'hooks/useCookieCleanup';
import { useToggleSidebar } from 'hooks/useToggleSidebar';
import CreateViewDialog from 'screens/space/components/dialogs/CreateViewDialog';
import { useSyncWithUrl } from 'screens/storyV2/useStoryPanes';
import {
  useContentTabs,
  useDisableMouseEvents,
  useFeedTickerVisible,
  useLeftHidden,
  useRightHidden,
  useSetCurrentTab,
  useSideEffectTour,
} from 'store';
import { useCleanChat } from 'store/chat';
import { useSideEffectMimir } from 'store/mimir';

import Header from './components/header/Header';
import Thumbnail from './components/header/navbar/scratchPad/components/thumbnail';
import useGetEditorCommands from './components/header/navbar/settings/hooks/useGetEditorCommands';
import LeftArea from './components/leftArea';
import RightPanel from './components/rightPanel/RightPanel';
import { useExternalData, useMainSettings } from './hooks';
import LeftResizeWrapper from './LeftResizeWrapper';
import RightResizeWrapper from './RightResizeWrapper';

import {
  Body,
  CenterContainer,
  CenterWrapper,
  ContentWrapper,
  MouseEventCatcher,
  SidebarWrapper,
  ThumbWrapper,
  TickerWrapper,
} from './styled';

const FeedTicker = lazy(() => import('features/feedsTicker'));

function MainLayout({ children }: Readonly<{ children: React.ReactElement }>) {
  const [isDragging, setIsDragging] = useState(false);
  const [externalData] = useExternalData();
  const { id: currentId } = useParams();
  const { appTitle, rightPanelWidth } = useMainSettings();
  const [contentTabs] = useContentTabs();
  const setCurrentTab = useSetCurrentTab();
  const [disableMouseEvents] = useDisableMouseEvents();

  useEffect(() => {
    if (currentId) {
      const tab = contentTabs.find((t) => t.id === currentId);
      if (tab) setCurrentTab(tab);
    } else {
      setCurrentTab(null);
    }
  }, [currentId, contentTabs, setCurrentTab]);

  // TODO: Remove typecast once feeds.js and sidebar.js is moved to typescript
  const [isTickerVisible, setIsTickerVisible] = useFeedTickerVisible() as [
    boolean,
    (val: boolean) => void,
  ];
  const [rightHidden] = useRightHidden();
  const [leftHidden] = useLeftHidden();

  useGetEditorCommands();
  useSyncWithUrl();
  useCleanChat();
  useToggleSidebar();
  useSideEffectTour();
  useSideEffectMimir();
  useLoadStudios();
  useCookieCleanup({ cookiePrefix: 'CloudFront', excludedPath: '/datastore' });
  useDailyNoteSubscription();

  return (
    <ContentWrapper $disableMouseEvents={disableMouseEvents}>
      <Header appTitle={appTitle} />
      <Body>
        <LeftResizeWrapper
          leftHidden={leftHidden}
          setIsDragging={setIsDragging}
          defaultLeftPanelWidth={rightPanelWidth}
        >
          <SidebarWrapper>
            <LeftArea />
          </SidebarWrapper>

          <RightResizeWrapper
            setIsDragging={setIsDragging}
            rightHidden={rightHidden}
            defaultRightPanelWidth={rightPanelWidth}
          >
            <CenterContainer>
              <CenterWrapper>
                {!!externalData.length && (
                  <ThumbWrapper>
                    <Thumbnail data={externalData} />
                  </ThumbWrapper>
                )}
                {children}
              </CenterWrapper>
            </CenterContainer>
            <SidebarWrapper>
              <MouseEventCatcher isDragging={isDragging} />
              <RightPanel />
            </SidebarWrapper>
          </RightResizeWrapper>
        </LeftResizeWrapper>
      </Body>
      {isTickerVisible && (
        <TickerWrapper>
          <Suspense fallback={<LoadingIndicator />}>
            <FeedTicker setVisible={setIsTickerVisible} />
          </Suspense>
        </TickerWrapper>
      )}
      <Portal />
      <AddMemberDialog />
      <EditFormDialog />
      <OrderFormDialog />
      <OrdersDialog />
      <AddInstanceDialog />
      <MyOrdersDialog />
      <OrderForms />
      <DatePicker />
      <MemberContextMenu />
      <PluginSearchContextMenu />
      <EditFieldValueDialog />
      <EditMetadataDialog />
      <EditStringDialog />
      <AsyncConfirmationDialog />
      <TreeChoiceDialog />
      <EditPermissionDialog />
      <CreateNewDialog />
      <SpaceDialog />
      <CommandMenu location="default" />
      <ToastComponent />
      <Preview />
      <CreateViewDialog />
      <ReloadOnAuthError />
    </ContentWrapper>
  );
}
export default MainLayout;
