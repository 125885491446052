import { MouseEventHandler, useCallback, useMemo } from 'react';

import { publishingPoints } from 'assets/icons/publishingPoints';
import useInstanceAssignees from 'features/instance/hooks/useInstanceAssignees';
import useInstancePermissions from 'features/instance/hooks/useInstancePermissions';
import { useInstanceMolecule } from 'features/instance/store/instance';
import useDinaNavigate from 'hooks/useDinaNavigate';
import { useMembersDialog } from 'store';
import { CreateStringUnionType } from 'types';
import variants from 'utils/instance/variants';

import { getVariant, toggleViewConstraint } from '../utils';

import { InstanceChangeObject } from './useInstanceCore';
import useInstancePublishing, { PublishSettingsInputType } from './useInstancePublishing';
import usePublishMetadata from './usePublishMetadata';

function useInstanceCardHeader(
  onInstanceChanged: (instanceChangeObject: InstanceChangeObject) => Promise<unknown>,
) {
  const { navigateTo } = useDinaNavigate();
  const {
    useInstanceValue,
    useCurrentDestination,
    usePublishingPointValue,
    usePublishAnchorEl,
    usePlatform,
    useDownloadData,
  } = useInstanceMolecule();

  const instance = useInstanceValue();
  const { assignMembers, assignedMembers } = useInstanceAssignees();
  const { canUpdateInstance, canRepublish } = useInstancePermissions();
  const [, setGetMembers] = useMembersDialog();
  const [destination] = useCurrentDestination();
  const [platform] = usePlatform();
  const [, setDownloadData] = useDownloadData();
  const variant = getVariant(instance?.mProperties?.platform ?? '');
  const publishingPoint = usePublishingPointValue();
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const publishMetadata = usePublishMetadata(instance?.mMetaData!);
  const { handlePublishSettingChange } = useInstancePublishing(onInstanceChanged);

  const [publishAnchorEl, setPublishAnchorEl] = usePublishAnchorEl();

  const { previewEndpoint, embeddedEndpoint } = instance?.mProperties?.provider || {};

  const hideAutomationTemplates = useMemo(
    () => variant !== variants.LINEAR || !!instance?.mProperties?.platformKind,
    [instance?.mProperties?.platformKind, variant],
  );

  const isDeleteEnabled =
    variant === variants.LINEAR
      ? (destination?.id ?? '').length > 0
      : instance?.mPublishingAt !== null;

  const canOpenDestination =
    instance?.mProperties?.platform === variants.LINEAR && destination?.title !== 'Unassigned';
  const canOpenStory = !(instance?.mTemplateId || instance?.isTemplateInstance);

  const isCMS = useMemo(() => variant === variants.CMS, [variant]);

  const publishingTime = useMemo(
    () =>
      variant === variants.LINEAR
        ? destination?.publishingTime ?? ''
        : instance?.mPublishingAt ?? '',
    [destination?.publishingTime, instance?.mPublishingAt, variant],
  );

  const canRepublishInstance =
    canRepublish && variant === variants.CMS && instance?.mState?.includes('published');

  const publishingIcon = useMemo(() => {
    const iconSrc =
      publishingPoints[platform?.mProperties?.platformIcon ?? ''] ??
      (publishingPoint === 'linear'
        ? publishingPoints[instance?.mProperties?.platformKind ?? '']
        : null) ??
      publishingPoints[publishingPoint ?? ''] ??
      publishingPoints.default;
    return iconSrc;
  }, [publishingPoint, instance?.mProperties?.platformKind, platform]);

  const showToggleButton = useMemo(
    () =>
      toggleViewConstraint.includes(variant as CreateStringUnionType<typeof toggleViewConstraint>),
    [variant],
  );

  const openDestination = useCallback(() => {
    if (
      instance?.mProperties?.platform === variants.LINEAR &&
      instance?.mProperties?.account?.accountId
    ) {
      navigateTo('rundown', instance?.mProperties?.account?.accountId);
    }
  }, [instance?.mProperties?.account?.accountId, instance?.mProperties?.platform, navigateTo]);

  const openStory = useCallback(() => {
    if (canOpenStory && instance?.mStoryId && instance?.mId) {
      navigateTo('story', instance.mStoryId, {
        tab: 'instances',
        entityId: instance.mId,
      });
    }
  }, [canOpenStory, instance?.mId, instance?.mStoryId, navigateTo]);

  const openPublishingSettings: MouseEventHandler<HTMLDivElement> = (event) => {
    if (canUpdateInstance) setPublishAnchorEl(event.currentTarget);
  };

  const openDialog = () => {
    setGetMembers({
      open: true,
      variant: 'all',
      removeTooltipText: 'Unassign',
      dialogTitle: 'Assign to Users/Teams/Departments',
      subHeader: 'Assigned To:',
      startWith: [...assignedMembers],
      showMessage: true,
      onOk: (newMembers, messageAssign) => {
        if (newMembers) {
          void assignMembers(newMembers, messageAssign);
        }
      },
    });
  };

  const handleDownload = useCallback(
    (contentType: string) => {
      if (!instance?.mId || !instance?.mRefId) return;
      setDownloadData({
        mId: instance?.mId,
        mRefId: instance?.mRefId,
        contentType,
        rundownSection: '-',
      });
    },
    [instance?.mId, instance?.mRefId, setDownloadData],
  );

  const onPublishConfirm = async (newPublishingSettings: PublishSettingsInputType) => {
    setPublishAnchorEl(null);
    await handlePublishSettingChange(newPublishingSettings);
  };

  const onPublishCancel = () => setPublishAnchorEl(null);

  return {
    assignedMembers,
    onAssigneesClick: openDialog,
    publishingIcon,
    destination,
    canOpenDestination,
    openDestination,
    canOpenStory,
    openStory,
    showToggleButton,
    isCMS,
    embeddedEndpoint,
    openPublishingSettings,
    publishAnchorEl,
    previewEndpoint,
    publishingTime,
    canRepublishInstance,
    publishMetadata,
    hideAutomationTemplates,
    onPublishConfirm,
    onPublishCancel,
    isDeleteEnabled,
    handleDownload,
  };
}

export default useInstanceCardHeader;
