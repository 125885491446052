/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { SearchParameters } from 'api/search';
import { CommandResults } from 'components/command/CommandResults';
import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import useToast from 'components/toast/useToast';
import updateMember from 'operations/mutations/updateMember';
import {
  getAssigneeIds,
  getRelevantStoryMetadata,
  isStoryFilter,
} from 'screens/space/components/widgets/SearchWidget';
import { useAllMembersKeyed } from 'store';
import { Metadata } from 'types/forms/forms';
import { AssignedMemberType, MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import { FilterValueType } from 'types/widget';

interface Props {
  filters?: FilterValueType;
  selectedDate?: DateRange;
  previewItem: (id: MemberType) => void;
  refreshCounter?: number;
  federatedSearchString?: string;
}

const Wrapper = styled('div')<{ isOver: boolean }>`
  height: 100%;
  box-sizing: border-box;
  border: ${({ isOver }) => (isOver ? '1px solid orange' : '1px solid transparent')};
  & [cmdk-list] {
    height: 100% !important;
  }
`;

const mergeAssignees = (
  assignees: string[],
  existingMembers: AssignedMemberType[],
  members: Record<string, AssignedMemberType>,
): AssignedMemberType[] => {
  const updatedAssignees = [
    ...existingMembers.map((m) => {
      return { mId: m.mId, mType: m.mType };
    }),
  ];

  for (const assignee of assignees) {
    if (existingMembers.find((m) => m.mId === assignee)) continue;
    const member = members[assignee];
    if (member) {
      updatedAssignees.push({ mId: member.mId, mType: member.mType });
    }
  }
  return updatedAssignees;
};

export default function SearchDeck({
  filters,
  selectedDate,
  previewItem,
  refreshCounter,
  federatedSearchString,
}: Readonly<Props>) {
  const { mdfsByMType } = useGetMdfs();
  const [allMembers] = useAllMembersKeyed();
  const [counter, setCounter] = useState(0);
  const { toast, errorToast } = useToast();
  const [updateStory] = useMutation(updateMember);

  const storyMdfFields = useMemo(() => {
    return keyBy(mdfsByMType.story?.fields ?? [], (f) => f.fieldId);
  }, [mdfsByMType]);

  const doUpdateMember = async (m: MemberType) => {
    if (isStoryFilter(filters) && m.mType === MemberTypeEnum.Story) {
      const md = getRelevantStoryMetadata(filters.searchParams, storyMdfFields);
      const existingMd = JSON.parse(m.metadata ?? '{}') as Metadata;
      const configuredAssignees = getAssigneeIds(filters.searchParams);

      if (md) {
        const mergedMd: Metadata = {
          ...existingMd,
          ...md.metadata,
        };
        await updateStory({
          variables: {
            input: {
              mId: m.mId,
              mdfId: md.mdfId,
              metadata:
                m.mdfId !== md.mdfId ? JSON.stringify(md.metadata) : JSON.stringify(mergedMd),
              ...(configuredAssignees.length && {
                mAssignedMembers: mergeAssignees(
                  configuredAssignees,
                  m.mAssignedMembers ?? [],
                  allMembers,
                ),
              }),
            },
          },
        })
          .then(() => {
            setCounter(counter + 1);
            toast({
              title: 'Updated',
              description: 'Story updated',
              type: 'success',
            });
          })
          .catch(errorToast);
      }
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: ['STORY_DRAG'],
    drop(p: MemberType) {
      void doUpdateMember(p);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    setCounter(counter + 1);
  }, [refreshCounter]);

  return (
    <Wrapper ref={(ref) => drop(ref)} isOver={isOver}>
      <CommandResults
        storedSearchParams={filters?.searchParams as SearchParameters}
        selectedDate={selectedDate}
        previewItem={previewItem}
        refreshCounter={counter}
        federatedSearchString={federatedSearchString}
      />
    </Wrapper>
  );
}
