/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { memo, useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';
import { ReactEditor, useSlate } from 'slate-react';

import DragAndDrop from 'components/editor/components/dragAndDrop';
import { onAssetDrop } from 'components/editor/components/paragraph/utils';
import addMedia from 'components/editor/components/primaryAutomation/components/mediaDropZone/utils/addClip';
import {
  createPrimaryAutomationElement,
  insertPrimaryAutomationElementV2,
} from 'components/editor/components/primaryAutomation/utils/insertPrimaryAutomationElementV2';
import { useEditorContext } from 'components/editor/EditorContext';
import { getDefaultTemplate } from 'components/editor/utils';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip';
import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import { mapMimirItem, verifyMimirItem } from 'store/scratchpad';
import { BlockElement } from 'types';
import { DroppedAsset } from 'utils/normalizeAssetData';
import { mediaTypes } from 'utils/rundownItemTypes';

import { DropZone, StyledParagraph } from './styled';

interface UnknownField {
  name: string;
  value: unknown;
  isVisible: boolean | null;
}

const BaseParagraph = ({ attributes, children, element }: Readonly<BlockElement>) => {
  const editor = useSlate();
  const { errorToast } = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { templates } = useGetAutomationTemplates();
  const { update, doLock, isLockedByAnotherUser } = useEditorContext();
  const [nativeIsOver, setNativeIsOver] = useState(false);

  // Internal drop logic
  const [{ isOver }, drop] = useDrop({
    accept: [mediaTypes.CLIP],
    canDrop: (item: { type: string; payload: DroppedAsset }) => item.payload.itemType === 'image',
    drop(item: { type: string; payload: DroppedAsset }) {
      if (item.payload.itemType === 'image') {
        doLock?.();
        onAssetDrop({ payload: item.payload }, editor, element, update).catch((err) =>
          // eslint-disable-next-line no-console
          console.error(err),
        );
      }
      return;
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
      overId: monitor.getItem<{ id: string }>(),
    }),
  });

  const insertAutomationItem = useCallback(
    async (item: DroppedAsset) => {
      if (!update) return;

      const {
        variant: templateVariant,
        name,
        description,
        fields,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      } = getDefaultTemplate('package', templates) as {
        variant: string;
        name: string;
        description: string;
        fields: UnknownField[];
      };
      const data: Record<string, any> = {
        templateVariant,
        name,
        description,
        metaData: [] satisfies UnknownField[],
      };
      if (fields?.length) {
        data.metaData = fields.map(({ name: fieldName, value, isVisible }) => ({
          name: fieldName,
          value,
          isVisible: isVisible === null ? true : isVisible,
        }));
      }
      const newElement = createPrimaryAutomationElement(data);
      insertPrimaryAutomationElementV2({
        editor,
        update,
        data,
        isNewline: false,
        elementToInsert: newElement,
        droppedOnElement: element,
      });
      void addMedia(editor, newElement, item, update, errorToast);
      ReactEditor.focus(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templates],
  );

  // External drop logic
  const onNativeDragOver = () => {
    setNativeIsOver(true);
  };

  const onNativeDragLeave = () => {
    setNativeIsOver(false);
  };

  const onNativeDrop = (ev: React.DragEvent) => {
    try {
      const data = ev.dataTransfer.getData('text/plain');
      console.log('data found:', data);
      const parsed = JSON.parse(data) as unknown;
      if (verifyMimirItem(parsed)) {
        const item = mapMimirItem(data) as DroppedAsset;
        doLock?.();
        void insertAutomationItem(item);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Something went wrong', err);
    } finally {
      setNativeIsOver(false);
    }
  };

  const isCombinedOver = isOver || nativeIsOver;

  return (
    <DragAndDrop element={element} isDragDisabled={true}>
      <DropZone
        $isOver={isCombinedOver}
        {...attributes}
        onDragOver={onNativeDragOver}
        onDrop={onNativeDrop}
        onDragLeave={onNativeDragLeave}
      >
        <Tooltip title={isCombinedOver ? 'Drop to place below this paragraph' : ''}>
          <StyledParagraph
            ref={(ref) => {
              drop(ref);
            }}
            $locked={!!isLockedByAnotherUser}
            onClick={doLock}
          >
            {children}
          </StyledParagraph>
        </Tooltip>
      </DropZone>
    </DragAndDrop>
  );
};

export default memo(BaseParagraph);
